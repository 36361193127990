import React from "react";
import { Outlet } from "react-router-dom";

const Account = (props) => {

    return <>
        <div className="login p-0" style={{ justifyContent: 'center', width: '100%' }}>
            <div className="row m-0  w-100 d-flex" style={{ justifyContent: 'center' }}>
                <div className="col-lg-6 col-md-12" >
                    <div className="content" >
                        <Outlet />
                    </div>
                </div>
                {/* <div className="col-lg-6 col-md-12">
                    <div className="right">
                        <h2 className="text">Let Our<br/> Family Help Yours</h2>
                    </div>
                </div> */}
            </div>
        </div>

        {/* <section className="login p-0">
            <div className="row m-0">
                <div className="col-lg-6 col-md-12 order-md" >
                    <div className="content">
                        <Outlet />
                    </div>
                </div>
                <div className="col-lg-6 col-md-12">
                    <div className="right">
                        <h2 className="text">Let Our<br/> Family Help Yours</h2>
                    </div>
                </div>
            </div>
        </section> */}
    </>
}

export default Account;