import { useState, useCallback, useEffect } from 'react';
import useAxios from "../../hooks/useAxios";
import Constants from "../../Constants";
import { toaster } from "../../utils/toast";
import { separateFilenameAndExtension } from '../../utils/helper';
import DOMPurify from 'dompurify';
import axios from 'axios';
import { useTemplateContext } from './TemplateContext';

const useTemplateForm = ({ templateType }) => {
    const [templateData, setTemplateData] = useState({});
    const [fieldLoader, setFieldLoader] = useState({});
    const [state, setState] = useState('California');
    const { postData } = useAxios();
    const { getData, isLoading: getTemplateIsLoading } = useAxios();
    const { fontFamily, setFontFamily } = useTemplateContext()

    const removeFontFamilyStyle = (html) => {
        return html?.replace(/font-family:[^;"}]*;?/gi, '');
    }

    const getTemplate = useCallback(async () => {
        let { success, data, message } = await getData(`${Constants.ApiUrl.template[`get${templateType}`]}?state=${state}`, { timeout: 20000 });
        if (success) {
            data = JSON.parse(removeFontFamilyStyle(JSON.stringify(data)))
            setTemplateData(data);
            if (data?.fontFamily) {
                setFontFamily(data?.fontFamily)
            }
        } else {
            toaster({ message, success });
        }
    }, [getData, state, templateType]);

    useEffect(() => {
        getTemplate();
    }, [getTemplate]);

    const addUpdateTemplate = async (payload, companyLogoFile) => {
        payload = { ...payload, state }
        const { success, data: { updatedTemplate, putCompanyLogoSignedUrl }, message } = await postData(Constants.ApiUrl.template[`addUpdate${templateType}`], payload, {
            timeout: 20000
        });
        if (success) {
            if (companyLogoFile && putCompanyLogoSignedUrl) {
                const uploadFileRes = await axios.put(putCompanyLogoSignedUrl, companyLogoFile, {
                    headers: {
                        'Content-Type': companyLogoFile.type,
                    },
                });

                console.log(uploadFileRes);
                setTemplateData((prevData) => ({
                    ...prevData,
                    ...updatedTemplate,
                    companyLogo: URL.createObjectURL(companyLogoFile)
                }));
            } else {
                setTemplateData((prevData) => ({
                    ...prevData,
                    ...updatedTemplate,
                }));
            }
        }
        toaster({ message, success });
    }

    const onFileSelected = async (e) => {
        const file = e.target.files[0]
        const { ext } = separateFilenameAndExtension(file.name);
        if (["pdf", "xlsx", "docx", "zip", "txt"].includes(ext)) {
            alert('Un-Supported files');
        } else {
            const payload = {
                companyLogo: {
                    name: file.name,
                    mimetype: file.type
                }
            }
            addUpdateTemplate(payload, file);
        }
    };

    const onChangeState = (e) => {
        e.preventDefault();
        setState(e.target.value);
    }

    const onChangeFontFamily = (e) => {
        e.preventDefault();
        const value = e.target.value
        setFontFamily(value);
        const payload = { fontFamily: value }
        addUpdateTemplate(payload)
    }

    const handelTemplateDataChange = (updatedTemplate) => {
        setTemplateData((prevData) => ({
            ...prevData,
            ...updatedTemplate
        }));
    }

    const onBlurHandler = async ({ field, value }) => {
        const payload = { [field]: value }
        toggelFieldLoader(field)
        await addUpdateTemplate(payload);
        toggelFieldLoader(field)
    }

    const toggelFieldLoader = (field) => {
        setFieldLoader((prevState) => ({ ...prevState, [field]: !prevState?.[field] }))
    }

    const sanitizeHtml = (html) => DOMPurify.sanitize(html);

    const removeCompanyLogo = () => {
        const payload = { "companyLogo": "removeLogo" }
        addUpdateTemplate(payload)
    }

    const onChangeAmoutText = (e) => {
        e.preventDefault();
        const value = e.target.checked
        const payload = { 'billedAmountHeading': value }
        addUpdateTemplate(payload)
    }

    return {
        templateData,
        state,
        onFileSelected,
        onChangeState,
        onBlurHandler,
        sanitizeHtml,
        getTemplateIsLoading,
        handelTemplateDataChange,
        fieldLoader,
        onChangeFontFamily,
        fontFamily,
        onChangeAmoutText,
        removeCompanyLogo
    };
};

export default useTemplateForm;