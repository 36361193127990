

import React, { useEffect } from 'react';
import 'react-quill/dist/quill.snow.css';
import '../style.css';
import './template.css';
import DefaultThirdPartyNonPolicyLimitDemand from './DefaultThirdPartyNonPolicyLimitDemand';
import DefaultThirdPartyPolicyLimitDemand from './DefaultThirdPartyPolicyLimitDemand';
import DefaultUIMNonPolicyLimitDemand from './DefaultUIMNonPolicyLimitDemand';
import DefaultUIMPolicyLimitDemand from './DefaultUIMPolicyLimitDemand';
import withContext from '../../hoc/withContext ';
import { TemplateProvider, useTemplateContext } from './TemplateContext';
import UMPLDTemplate from './UMPLDTemplate';
import UMNPLDTemplate from './UMNPLDTemplate';
import SimplifiedTPPLDTemplate from './SimplifiedTPPLDTemplate';
import SimplifiedUMPLDTemplate from './SimplifiedUMPLDTemplate';
import SimplifiedUIMPLDTemplate from './SimplifiedUIMPLDTemplate';


const Template = () => {
    const { fontFamily } = useTemplateContext()

    useEffect(() => {
        document.documentElement.style.setProperty('--quill-editor-default-font-family', fontFamily);
      }, [fontFamily]);

    return (
        <>
            <div className="container-fluid">
                <div className="col-md-12 mt-5">
                    <div className="tabs-wrap mt-4">
                        <div className="nav nav-pills nav-justified" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                            <button className="nav-link active" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">Third Party Policy Limit Demand</button>
                            <button className="nav-link" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">Third Party Non-Policy Limit Demand</button>
                            <button className="nav-link" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">UIM Policy Limit Demand</button>
                            <button className="nav-link" id="v-pills-settings-tab" data-bs-toggle="pill" data-bs-target="#v-pills-settings" type="button" role="tab" aria-controls="v-pills-settings" aria-selected="false">UIM Non-Policy Limit Demand</button>
                            <button className="nav-link" id="um_pld-tab" data-bs-toggle="pill" data-bs-target="#um_pld-settings" type="button" role="tab" aria-controls="um_pld-settings" aria-selected="false">UM Policy Limit Demand</button>
                            <button className="nav-link" id="um_n_pld-tab" data-bs-toggle="pill" data-bs-target="#um_n_pld-settings" type="button" role="tab" aria-controls="um_n_pld-settings" aria-selected="false">UM Non-Policy Limit Demand</button>

                            <button className="nav-link" id="simplified-tab" data-bs-toggle="pill" data-bs-target="#simplified-settings" type="button" role="tab" aria-controls="simplified-settings" aria-selected="false">Simplified Third Party Policy Limit Demand</button>

                            <button className="nav-link" id="simplified-uim-tab" data-bs-toggle="pill" data-bs-target="#simplified-uim-settings" type="button" role="tab" aria-controls="simplified-uim-settings" aria-selected="false">Simplified UIM Policy Limit Demand</button>

                            <button className="nav-link" id="simplified-um-tab" data-bs-toggle="pill" data-bs-target="#simplified-um-settings" type="button" role="tab" aria-controls="simplified-um-settings" aria-selected="false">Simplified UM Policy Limit Demand</button>
                            
                        </div>
                        <div className="tab-content" id="v-pills-tabContent">
                            <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab" tabIndex="0">
                                <DefaultThirdPartyPolicyLimitDemand />
                            </div>
                            <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab" tabIndex="0">
                                <DefaultThirdPartyNonPolicyLimitDemand />
                            </div>
                            <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab" tabIndex="0">
                                <DefaultUIMPolicyLimitDemand />
                            </div>
                            <div className="tab-pane fade" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab" tabIndex="0">
                                <DefaultUIMNonPolicyLimitDemand />
                            </div>
                            <div className="tab-pane fade" id="um_pld-settings" role="tabpanel" aria-labelledby="um_pld-settings-tab" tabIndex="0">
                                <UMPLDTemplate />
                            </div>
                            <div className="tab-pane fade" id="um_n_pld-settings" role="tabpanel" aria-labelledby="um_n_pld-settings-tab" tabIndex="0">
                                <UMNPLDTemplate />
                            </div>
                            <div className="tab-pane fade" id="simplified-settings" role="tabpanel" aria-labelledby="simplified-settings-tab" tabIndex="0">
                                <SimplifiedTPPLDTemplate />
                            </div>

                            <div className="tab-pane fade" id="simplified-uim-settings" role="tabpanel" aria-labelledby="simplified-uim-settings-tab" tabIndex="0">
                                <SimplifiedUIMPLDTemplate />
                            </div>

                            <div className="tab-pane fade" id="simplified-um-settings" role="tabpanel" aria-labelledby="simplified-um-settings-tab" tabIndex="0">
                                <SimplifiedUMPLDTemplate />
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </>
    );
};

export default withContext(Template, TemplateProvider) ;