import CaseService from "./CaseService";
import UserService from "./UserService";
import AuthService from './AuthService';
import Constants from "../Constants";
import PdfConverterService from './PdfConverterService';

export { CaseService, UserService, AuthService, PdfConverterService };

export const headers = () => Constants?.getAuthtoken()?.token != undefined && Constants?.getAuthtoken()?.token != null && {
    headers: {
        "authorization": `Bearer ${Constants?.getAuthtoken()?.token}`
    }
}
