import { Field, Formik, Form } from 'formik'
import * as Yup from 'yup'
import { Link } from "react-router-dom";
import { TextInput } from '../../components';
import { AuthService } from '../../api-services';
import { useState } from 'react';
import './style.css'
const ForgotPassword = () => {
    const [emaiSentSuccessfully , setemaiSentSuccessfully] = useState(0)
    const validationSchema = Yup.object().shape({
        email: Yup.string().required('Email is required').email('Please enter a valid email'),
    })
    const onSubmit = (values, setErrors) => {
        AuthService.ForgotPassword(values).then((res) => {
            setemaiSentSuccessfully(1)
        }).catch(err => {
            setErrors({ ...err?.response?.data?.message })
        })
    }
    return ( 
        <>
        {
            emaiSentSuccessfully === 0 ? 
            <div className="content">
            <div className="top">
                <div className="text">
                    <h1>Forgot Password?</h1>
                </div>
                <Formik
                    initialValues={{
                        email: '',
                        password: '',
                    }}
                    enableReinitialize={true}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setErrors }) => {
                        onSubmit(values, setErrors)
                    }}
                >
                    {() => (
                        <Form>
                            <div className="fields">
                                <div className="form-group">
                                    <Field name="email" removeMargin={true} className="form-control" shouldDollarRender={true} shouldUserRenderIcon={<> <span className="input-group-text" style={{
                                        width: "2.5rem",
                                        outline: "none",
                                        height: "48px",
                                        border: "none",
                                        position: "absolute"
                                    }} id="basic-addon1"><i className="fa-solid fa-user"></i></span></>} placeholder="Enter Email" component={TextInput} />
                                </div>
                            </div>
                            <button type='submit' className="btn-theme btn-login">Submit</button>
                        </Form>)}
                </Formik>
            </div>
            <br/>
            <Link className="text-theme" to="/account/login"><i className="fa-solid fa-arrow-left me-2"></i> Back to Login</Link>
        </div>
        :
        <section className="mail-seccess section">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 offset-lg-3 col-12">
                    <div className="success-inner">
                        <h1><i className="fa fa-envelope"></i><span>Your Mail Sent Successfully!</span></h1>
                        <p>Please check your mail</p>
                        <Link className="text-theme" to="/account/login"><i className="fa-solid fa-arrow-left me-2"></i> Back to Login</Link>
                    </div>
                </div>
            </div>
        </div>
    </section>
        }
        </>

    )
}

export default ForgotPassword;