import axios from 'axios';
import AppConfig from '../AppConfig';
import Constants from '../Constants';
import { headers } from '.';
class PdfConverterService {
    static pdfConverter = (payloadData) => {
        const url = `${AppConfig.ApiBaseUrl}${Constants.ApiUrl.pdfConverter}`;
        return axios.post(url, payloadData,{...headers()})
    }
}

export default PdfConverterService;