import axios from 'axios';
import AppConfig from '../AppConfig';
import Constants from '../Constants';
import { headers } from '.';
class CompaniesServies {
    static AddComapnie = (payloadData) => {
        const url = `${AppConfig.ApiBaseUrl}${Constants.ApiUrl.companies.addCompanies}`;
        return axios.post(url, payloadData,{...headers()})
    }

    static GetCompanies = (pageNumber , limit , searchText) => {
        const url = `${AppConfig.ApiBaseUrl}${Constants.ApiUrl.companies.getCompanies}?pageNumber=${pageNumber}&limit=${limit}&searchText=${searchText}`;
        return axios.get(url,{...headers()}).then(resp => resp.data);
    }

    static UpdateComopanies = (payloadData) => {
        const url = `${AppConfig.ApiBaseUrl}${Constants.ApiUrl.companies.updatecompanies}`;
        
        return axios.post(url, payloadData,{...headers()})
    }
    static FilterComopanies = ( pageNumber , limit , searchText , startDate , endDate ) => {
        const url = `${AppConfig.ApiBaseUrl}${Constants.ApiUrl.companies.filtercompanies}?pageNumber=${pageNumber}&limit=${limit}&searchText=${searchText}&startDate=${startDate}&enddate=${endDate}`;
        return axios.get(url,{...headers()})
    }
}

export default CompaniesServies;