export const DEMAND_TYPE = {
    TP_PLD: { key: 'tppld', text: 'Third Party Policy Limit Demand', value: 'TP_PLD' },
    TP_N_PLD: { key: 'tpnpld', text: 'Third Party Non-Policy Limit Demand', value: 'TP_N_PLD' },
    UIM_PLD: { key: 'uimpld', text: 'UIM Policy Limit Demand', value: 'UIM_PLD' },
    UIM_N_PLD: { key: 'uimnpld', text: 'UIM Non-Policy Limit Demand', value: 'UIM_N_PLD' },
    UM_PLD: { key: 'um_pld', text: 'UM Policy Limit Demand', value: 'UM_PLD' },
    UM_N_PLD: { key: 'um_n_pld', text: 'UM Non-Policy Limit Demand', value: 'UM_N_PLD' },
    Simplified_TP_PLD: { key: 'simplified_tp_pld', text: 'Simplified Third Party Policy Limit Demand', value: 'Simplified_TP_PLD' },
    Simplified_UIM_PLD: { key: 'simplified_uim_pld', text: 'Simplified UIM Policy Limit Demand', value: 'Simplified_UIM_PLD' },
    Simplified_UM_PLD: { key: 'simplified_um_pld', text: 'Simplified UM Policy Limit Demand', value: 'Simplified_UM_PLD' },
};