import React, { useEffect, useState } from "react";
import { CaseService } from "../../../api-services";
import { RenderIf } from "../../../components";
import AddCaseForm from "./components/AddCaseForm";
import { GenerateUUID, ParseAndFormatUtcDateTime } from "../../../Utils";
import ReactPagination from "../../../components/common/Pagination";
import useSortableData from "../../../components/customHook/SortableTable";
import io from 'socket.io-client';
import { useNavigate } from "react-router";
import ColorService from "../../../styleService/ColorService";
import TextPositonService from "../../../styleService/TextPositionService";
import LoggedInUserService from '../../../services/AuthService'
import { useLocation } from "react-router-dom";
import { BsCheck2Circle } from 'react-icons/bs'
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Dialog } from "../../../components";
import CaseSkeleton from "./caseSkeleton";
import { useDispatch, useSelector } from 'react-redux';
import { storeInjuryAnalysisEstern, storeDamageAnalysisEstern, storeLiablityEstern } from "../../../redux/actions/liabilty";
import { Field, Formik, Form } from 'formik'
import Constants from '../../../Constants';
import Skeleton from "react-loading-skeleton";
import '../style.css';
import axios from 'axios';
import { caseLimitReachedHtml } from "../../../utils/constant";
import { confirm } from "../../../utils/swal";
import { setUser } from "../../../redux/actions/users";
import { toaster } from "../../../utils/toast";
import { debounce, removeSpecialCharacters, separateFilenameAndExtension } from "../../../utils/helper";
import LinearProgressWithLabel from "../../../components/common/LinearProgressWithLabel";
import useAxios from "../../../hooks/useAxios";
import XLoader from "../../../components/common/XLoader";

const Cases = () => {
   const [skeletonLoader, setSkeletonLoader] = useState({
      isActive: false,
      count: 10
   });
   const dispatch = useDispatch();
   const [isAddCaseFromOpen, setAddCaseFromOpen] = useState(false);
   const [casesList, setCasesList] = useState([]);
   const location = useLocation();
   const { items, requestSort, sortConfig } = useSortableData(casesList);
   const [currentPage, setCurrentPage] = useState(1);
   const [totalCount, settotalCount] = useState("");
   const [totalPages, settotalPages] = useState(0);
   const [searchText, setsearchText] = useState("")
   const [showEntries, setshowEntries] = useState(10)
   const [demandLetterProgress, setDemandLetterPorgress] = useState('');
   const navigate = useNavigate();
   const [isModalOpen, setIsModalOpen] = useState(false);
   const [isDeleteCaseModalOpen, setIsDeleteCaseModalOpen] = useState(false)
   const [errorLogData, setErrorLogData] = useState([]);
   const [caseId, setCaseId] = useState('');
   const [addCaseIsLoading, setAddCaseIsLoading] = useState(false);
   const [typeOfDemandDraft, setTypeOfDemandDraft] = useState('');
   const { rollOverCredits = 0, remainingDemand = 0 } = useSelector((state) => state.users.subscription || {});
   const [caseFileUploadProgress, setCaseFileUploadProgress] = useState(0);
   const { postData } = useAxios()
   const { getData: getCasePostData, isLoading: getCaseIsLoading } = useAxios()

   const toggleSkeletonLoader = (value, count = 10) => {
      setSkeletonLoader((prevalue) => {
         return {
            ...prevalue,
            isActive: value,
            count
         }
      });
   };

   useEffect(() => {
      if (!skeletonLoader?.isActive) {
         toggleSkeletonLoader(true)
         getCases().then(() => { console.log("Case is loaded") });
      }
      console.log(process.env.REACT_APP_API_BASE_URL)
   }, [currentPage, searchText, showEntries, location.search]) //items

   useEffect(() => {
      const tdata = LoggedInUserService.GetLoggedInUserData();
      const socket = io(process.env.REACT_APP_PFQ_BASE_URL, {  //"http://localhost:10000"
         query: {
            id: tdata?.id
         },
         reconnection: true,
         withCredentials: true
      });
      if (socket) {
         socket.on('connect', (data) => {
            console.log('Connected to Socket.IO server.');
         });

         socket?.on('toaster', (data) => {
            toaster(data)
         })

         socket?.on('caseUpdates', (data) => {
            setCasesList((prev) => {
               let arrayCase = [...prev]
               let findCase = arrayCase?.findIndex((item) => item?._id === data?.caseId)
               if (findCase !== -1 && data) {
                  arrayCase[findCase] = { ...arrayCase[findCase], ...data }
               }
               return arrayCase
            })
         })

         return () => {
            socket.disconnect()
         }
      }
   }, [])

   const getCases = async () => {
      const url = `${Constants.ApiUrl.case.getCases}?pageNumber=${currentPage}&limit=${showEntries}&searchText=${searchText}`
      const resp = await getCasePostData(url);
      const { success } = resp
      if (success) {
         const { cases, totalCount, totalPages, subscription } = resp.data
         setCasesList(cases);
         settotalPages(totalPages);
         settotalCount(totalCount)
         toggleSkeletonLoader(false)
         dispatch(setUser({ subscription }))
      } else {
         const { message } = resp
         toaster({ message, success })
      }
   }

   const addCases = async (formPayload) => {
      const injuryData = formPayload?.data?.injury
      setAddCaseIsLoading(true);
      toggleSkeletonLoader(true, 1);
      dispatch(storeDamageAnalysisEstern({}));
      dispatch(storeInjuryAnalysisEstern({}));
      dispatch(storeLiablityEstern({}));

      const postMedicalRecords = injuryData?.postAccident?.flatMap(providerLevel => {
         const providerName = providerLevel?.providerName;
         const medicalType = providerLevel?.medicalType || 'All Other Medical Records';
         
         return providerLevel?.medicalRecords?.map(file => {
            const blob = new Blob([file], { type: file.type });
            const newFile = new File([blob], file.name, { type: file.type });
            
            return {
               file: newFile,
               metaData: {
                  providername: providerName,
                  medicaltype: medicalType,
                  originalfilename: file.name,
                  typeofrecord: 'postMedicalRecords'
               }
            };
         }) || [];
      });
      
      const postMedicalBills = injuryData?.postAccident?.flatMap(providerLevel => {
         const providerName = providerLevel?.providerName;
         const medicalType = providerLevel?.medicalType || 'All Other Medical Records';
         
         return providerLevel?.medicalBills?.map(file => {
            const blob = new Blob([file], { type: file.type });
            const newFile = new File([blob], file.name, { type: file.type });
            
            return {
               file: newFile,
               metaData: {
                  providername: providerName,
                  medicaltype: medicalType,
                  originalfilename: file.name,
                  typeofrecord: 'postMedicalBills'
               }
            };
         }) || [];
      });

      const preMedicalRecords = injuryData?.preAccident?.flatMap(providerLevel => {
         const providerName = providerLevel?.providerName;
         const medicalType = providerLevel?.medicalType || 'All Other Medical Records';
         
         return providerLevel?.medicalRecords?.map(file => {
            const blob = new Blob([file], { type: file.type });
            const newFile = new File([blob], file.name, { type: file.type });
            
            return {
               file: newFile,
               metaData: {
                  providername: providerName,
                  medicaltype: medicalType,
                  originalfilename: file.name,
                  typeofrecord: 'preMedicalRecords'
               }
            };
         }) || [];
      });
      
      const processFiles = (fileArray, typeOfRecord) => {
         return fileArray.map((file) => {
           const blob = new Blob([file], { type: file.type });
            return {
               file: new File([blob], file.name, { type: file.type }), metaData: {
                  originalfilename: file.name,
                  typeofrecord: typeOfRecord
               }
            };
         });
       };
       
       let policeReport = processFiles([...formPayload.policeReport], 'policeReport');
       let accidentScenesPhotos = processFiles([...formPayload.accidentScenesPhotos], 'accidentScenesPhotos');
       let bodyInjuryFiles = processFiles([...formPayload.bodyInjuryFiles], 'bodyInjuryFiles');

      let selectedAccidentFiles = formPayload.data.liability?.selectedAccidentFiles;

      let selectedBodyInjury = formPayload.data.liability?.selectedBodyInjury;

      const formdata = new FormData();
      formdata.append("liability", JSON.stringify(formPayload.data.liability))
      formdata.append("injury", JSON.stringify(formPayload.data.injury))
      formdata.append("damage", JSON.stringify(formPayload.data.damage))


      //  Generate Presigned Url Code  start
      const preSignedObj = {}
      const filesObj = {}
      let selectedAccidentFilesIndexArr = []
      let selectedBodyInjuryIndexArr = []

      const setObjStructure = (objName, fileDataArr) => {
         const filteredArr = fileDataArr.filter(item => item?.file !== null && item?.file !== undefined && item?.file !== "");
         if (filteredArr?.length <= 0) { return null }
         preSignedObj[objName] = filteredArr.map((v, index) => {
            const file = v.file
            const metaData = v.metaData
            const originalfilename = metaData?.originalfilename
            if (objName === "accidentScenesPhotos" && selectedAccidentFiles.includes(originalfilename)) {
               selectedAccidentFilesIndexArr.push(index)
            }
            if (objName === "bodyInjuryFiles" && selectedBodyInjury.includes(originalfilename)) {
               selectedBodyInjuryIndexArr.push(index)
            }
            filesObj[objName] = filesObj[objName] || [];
            filesObj[objName].push(file);
            const uuid = GenerateUUID()
            const { ext } = separateFilenameAndExtension(originalfilename)
            
            return {
               fileName: `${uuid}.${ext}`,
               fileType: file.type,
               metaData: metaData
            }
         });
      }

      if (Array.isArray(policeReport) && policeReport?.length > 0) {
         setObjStructure("policeReport", policeReport);
      }
      if (Array.isArray(accidentScenesPhotos) && accidentScenesPhotos?.length > 0) {
         setObjStructure("accidentScenesPhotos", accidentScenesPhotos);
      }
      if (Array.isArray(bodyInjuryFiles) && bodyInjuryFiles?.length > 0) {
         setObjStructure("bodyInjuryFiles", bodyInjuryFiles);
      }

      if (Array.isArray(postMedicalRecords) && postMedicalRecords?.length > 0) {
         setObjStructure("medicalRecordsPdf", postMedicalRecords);
      }
      
      if (Array.isArray(preMedicalRecords) && preMedicalRecords?.length > 0) {
         setObjStructure("preMedicalRecordsPdf", preMedicalRecords);

      }

      if (Array.isArray(postMedicalBills) && postMedicalBills?.length > 0) {
         setObjStructure("medicalBillsfile", postMedicalBills);
      }

      const totalCaseFileSize = Object.values(filesObj).flat().reduce((total, file) => total + file.size || 0, 0)
      let uploadedBytes = new Map();

      const updateCaseFileUploadProgress = (file, loaded, total) => {
         uploadedBytes.set(file.name, loaded);
         let totalUploaded = Array.from(uploadedBytes.values()).reduce((sum, bytes) => sum + bytes, 0);
         setCaseFileUploadProgress((totalUploaded / totalCaseFileSize) * 100);
      };

      if (Object.keys(preSignedObj).length >= 1) {
         const generatePresignedUrlsRes = await CaseService.generatePresignedUrls({ preSignedObj: preSignedObj });
         formdata.append("s3UniqueId", generatePresignedUrlsRes.data.s3UniqueId);
         if (generatePresignedUrlsRes?.status === 200) {
            const preSignedObjRes = generatePresignedUrlsRes?.data?.preSignedObjRes
            for (const preSignedKey of Object.keys(preSignedObjRes)) {
               const preSignedUrls = preSignedObjRes[preSignedKey];
               const urlKeyList = await Promise.all(preSignedUrls.map(async (preSignedUrl, index) => {
                  const file = filesObj[preSignedKey][index];
                  try {
                     const uploadFileRes = await axios.put(preSignedUrl.signedUrl, file, {
                        headers: {
                           'Content-Type': file.type,
                        },
                        onUploadProgress: (event) => {
                           updateCaseFileUploadProgress(file, event.loaded, event.total);
                        }
                     });

                     if (uploadFileRes.status === 200) {
                        return preSignedUrl.urlKey;
                     }
                  } catch (error) {
                     console.log("Error to Upload File to S3", error);
                  }
               }));

               if (preSignedKey === "accidentScenesPhotos") {
                  let awsSelectedPath = selectedAccidentFilesIndexArr.map((value) => {
                     return urlKeyList[value]
                  })
                  formdata.append("selectedAccidentFiles", JSON.stringify(awsSelectedPath));
               }
               if (preSignedKey === "bodyInjuryFiles") {
                  let awsSelectedPath = selectedBodyInjuryIndexArr.map((value) => {
                     return urlKeyList[value]
                  })
                  formdata.append("selectedBodyInjuryFiles", JSON.stringify(awsSelectedPath));
               }
               formdata.append(preSignedKey, JSON.stringify(urlKeyList));
            }
         } else {
            console.log("Error to Generate PreSigned Url")
         }
      }

      await CaseService.AddCase(formdata).then(async (response) => {
         setAddCaseIsLoading(false);
         setAddCaseFromOpen(false)
         setCaseFileUploadProgress(0)
         getCases()
         dispatch(storeDamageAnalysisEstern({}));
         dispatch(storeInjuryAnalysisEstern({}));
         dispatch(storeLiablityEstern({}));
      }).catch((err) => console.log(err))

   }

   const downloadDemandDraft = async ({ caseId, caseName }) => {

      if (!typeOfDemandDraft) {
         toaster({ message: 'Please select Type of Demand', success: false });
      }

      const { success, data, message } = await postData(Constants.ApiUrl.generate, { caseId, typeOfDemandDraft });
      if (success) {
         const { wordUrl } = data;
         let link = document.createElement("a");
         link.download = `${caseName}-${typeOfDemandDraft}.docx`
         link.href = wordUrl;
         link.click();
      } else {
         toaster({ message, success });
      }
   }

   const handlePageChange = (event, pageNumber) => {
      setCurrentPage(pageNumber);
   };
   const getClassNamesFor = (name) => {
      if (!sortConfig) {
         return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
   };

   const addCaseFrom = async () => {
      const caseLimit = remainingDemand + rollOverCredits
      if (caseLimit <= 0) {
         const result = await confirm({ title: 'Subscription Limit Reached', html: caseLimitReachedHtml });
         if (result.isConfirmed) {
            const newPath = `${location.pathname}?add`;
            navigate(newPath);
            setDemandLetterPorgress('')
            setAddCaseFromOpen(true)
         }
      } else {
         const newPath = `${location.pathname}?add`;
         navigate(newPath);
         setDemandLetterPorgress('')
         setAddCaseFromOpen(true)
      }

   }

   const onToggleDialog = (isDialogOpen) => {
      setIsModalOpen(isDialogOpen)
   }

   const onToggleDeleteCaseDialog = (isDialogOpen) => {
      setIsDeleteCaseModalOpen(isDialogOpen)
   }

   const openModal = async (id) => {
      setIsModalOpen(true)
      const data = await CaseService.GetErrorlog(id, currentPage, showEntries,)
         .then((resp) => resp)
         .catch((error) => console.log(error))
      setErrorLogData(data.data)
   }
   const openDeletCaseeModal = (Id) => {
      setCaseId(Id)
      setIsDeleteCaseModalOpen(true)
   }

   const deleteFreezedCase = async () => {
      try {
         const deletedCase = await CaseService.deleteCase(caseId);
         console.log('Case deleted:', deletedCase);
         await getCases();
         setIsDeleteCaseModalOpen(false)
      } catch (error) {
         console.error('Error deleting case:', error);
         // Handle errors as needed
      }
   };

   const BackToCases = () => {
      setAddCaseFromOpen(false)
      localStorage.removeItem('formState');
      dispatch(storeDamageAnalysisEstern({}));
      dispatch(storeInjuryAnalysisEstern({}));
      dispatch(storeLiablityEstern({}));
   }

   const changeTypeOfDemandDraftHandler = (value) => {
      setTypeOfDemandDraft(value)
   }

   const handleSearchInputChange = (e) => {
      setsearchText(e.target.value)
   };

   const debouncedHandleSearchChange = debounce(handleSearchInputChange, 500);


   return <div className="listing">
      <div class="title d-flex justify-content-between align-items-center">

         <span>List of All Cases</span>
         {
            !isAddCaseFromOpen
               ?
               <button class="btn btn-viewPdf" onClick={() => addCaseFrom()} ><i class="fa-solid fa-plus me-2"></i> Add Case</button>
               :
               <button class="btn btn-viewPdf" onClick={() => BackToCases()}><i class="fa-solid fa-arrow-left me-2"></i>Back to Cases</button>
         }

      </div>

      <RenderIf shouldRender={isAddCaseFromOpen}>
         <AddCaseForm addCases={addCases} />
      </RenderIf>


      <RenderIf shouldRender={(!isAddCaseFromOpen)}>
         <div id="myTable_wrapper" class="dataTables_wrapper no-footer">
            <div class="dataTables_length" id="myTable_length">
               <label>
                  Show
                  <select value={showEntries} onChange={(e) => setshowEntries(parseInt(e.target.value))} name="myTable_length" aria-controls="myTable" class="">
                     <option value="10">10</option>
                     <option value="25">25</option>
                     <option value="50">50</option>
                     <option value="100">100</option>
                  </select>
                  entries
               </label>
            </div>
            <div id="myTable_filter" class="dataTables_filter"><label>Search:<input onChange={debouncedHandleSearchChange} type="search" class="" placeholder="" aria-controls="myTable" /></label></div>


           
            {getCaseIsLoading && <div className='tableLoader'>
               <XLoader />
            </div>}

            {!getCaseIsLoading && <>
               <table id="myTable" class="display dataTable no-footer" aria-describedby="myTable_info">
                  <thead>
                     <tr>
                        <th onClick={() => requestSort('_id')} className={`sorting ${getClassNamesFor("_id")}`} tabindex="0" aria-controls="myTable" rowspan="1" colspan="1" aria-sort={sortConfig?.direction === "sorting_asc" ? "ascending" : "descending"} aria-label={sortConfig?.direction === "sorting_asc" ? "ascending" : "descending"} style={{ width: "148.51px" }}>Case ID</th>
                        <th onClick={() => requestSort('detailsInput.liability.caseName')} className={`sorting ${getClassNamesFor("detailsInput.liability.caseName")}`} tabindex="0" aria-controls="myTable" rowspan="1" colspan="1" aria-label="Title: activate to sort column ascending" style={{ width: "420.417px" }}>Title

                        </th>
                        <th onClick={() => requestSort('detailsInput.liability.caseType')} className={`sorting  ${getClassNamesFor("detailsInput.liability.caseType")}`} tabindex="0" aria-controls="myTable" rowspan="1" colspan="1" aria-label="Case Type: activate to sort column ascending" style={{ width: "127.156px" }}>Case Type</th>
                        <th onClick={() => requestSort('createdOn')} className={`sorting  ${getClassNamesFor("createdOn")}`} tabindex="0" aria-controls="myTable" rowspan="1" colspan="1" aria-label="Case Added Date: activate to sort column ascending" style={{ width: "204.583px" }}>Case Added Date</th>
                        <th style={{ width: "204px" }}>Police Report </th>
                        <th style={{ width: "204px" }}>Medical Records</th>
                        <th style={{ width: "204px" }}>Pre-Medical Records</th>
                        <th style={{ width: "204px" }}>Medical Bills </th>
                        <th style={{ width: "204px" }}>Demand Letter </th>
                        <th style={{ width: "204px" }}>Completion Status </th>
                        <th style={{ width: "800px" }}>Type of Demand </th>
                        <th style={{ width: "204px" }}>Completion Mark </th>
                     </tr>
                  </thead>
                  <tbody>
                     {skeletonLoader?.isActive && [...Array(skeletonLoader?.count)].map((_, skeletonIndex) => {
                        return <CaseSkeleton skeletonIndex={skeletonIndex} />
                     })}
                     {items.map((x, index) => {
                        const isCaseInProgress = typeof x?.isCaseGeneratedSccessfuly === 'undefined';
                        const isCaseFailed = !isCaseInProgress && !!!x?.isCaseGeneratedSccessfuly;
                        const isCaseGeneratedSuccessfully = !isCaseInProgress && !!x?.isCaseGeneratedSccessfuly
                        const isDemandGeneratedSuccessfully = x?.resultProgress?.demandLetter_progress === "Successful"

                        return <tr class={index % 2 === 0 ? "odd" : 'even'} >
                           <td class="sorting_1"

                              style={{ color: demandLetterProgress === "Successful" && index === 0 ? "blue" : "", cursor: 'pointer' }}

                           >{x._id}</td>
                           <td class="d-flex case-title">
                              <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                 <span class="word" >{x?.detailsInput?.liability?.caseName?.[0]}</span>
                                 <div class="value">
                                    <p>{x?.detailsInput?.liability?.caseName}</p>

                                 </div>
                              </span>
                           </td>
                           <td>{x?.detailsInput?.liability?.caseType}</td>
                           <td>{ParseAndFormatUtcDateTime(x.createdOn)}</td>
                           {
                              x?.resultProgress?.medicalRecords_progress === "" && x?.resultProgress?.demandLetter_progress === "" && x?.resultProgress?.policeReport_Progress === "" && x?.resultProgress?.medicalBills_progress === ""
                                 && x?.resultProgress?.medicalRecords_progress === "" && x?.resultProgress?.demandLetter_progress === "" ?
                                 <>
                                    <td><Skeleton /></td>
                                    <td><Skeleton /></td>
                                    <td><Skeleton /></td>
                                    <td><Skeleton /></td>
                                    <td><Skeleton /></td>
                                 </>
                                 :
                                 <>
                                    <td>
                                       <RenderIf shouldRender={x?.resultProgress?.policeReport_Progress}>
                                          <div style={{ width: '9rem', height: 32, border: '2px solid black', color: 'black', borderRadius: '10px', textAlign: 'center', }}>
                                             <span style={{

                                                position: 'absolute', marginLeft: x?.resultProgress?.policeReport_Progress?.length >= 2 ? TextPositonService.policeReportProgress(x?.resultProgress?.policeReport_Progress?.split(' ')) : '-20px',
                                                color: ColorService.policeReportProgress(Array.isArray(x?.resultProgress?.policeReport_Progress) ? x?.resultProgress?.policeReport_Progress.split(' ') : x?.resultProgress?.policeReport_Progress)
                                             }}>
                                                {x?.resultProgress?.policeReport_Progress}{isNaN(x?.resultProgress?.policeReport_Progress) ? "" : "%"}</span>
                                             <div style={{
                                                width: x?.resultProgress?.policeReport_Progress === "Successful" ? '100%' : x?.resultProgress?.policeReport_Progress + '%',
                                                height: '1.9rem',
                                                backgroundColor: '#18479a',
                                                borderRadius: '7px',
                                                color: 'white',
                                                textAlign: 'center',
                                                fontSize: 12,
                                                // paddingTop: 8
                                             }} ></div>
                                          </div>
                                       </RenderIf>
                                    </td>
                                    <td>

                                       <RenderIf shouldRender={x?.resultProgress?.medicalRecords_progress}>

                                          <div style={{ width: '9rem', height: 32, border: '2px solid black', color: 'black', borderRadius: '10px', textAlign: 'center', }}>
                                             <span style={{
                                                position: 'absolute', marginLeft: x?.resultProgress?.medicalRecords_progress?.length >= 2 ? TextPositonService.progress(x?.resultProgress?.medicalRecords_progress?.split(" ")) : '-15px',
                                                color: ColorService.progress(Array.isArray(x?.resultProgress?.medicalRecords_progress) ? x?.resultProgress?.medicalRecords_progress.split(' ') : x?.resultProgress?.medicalRecords_progress)
                                             }}>
                                                {x?.resultProgress?.medicalRecords_progress}{isNaN(x?.resultProgress?.medicalRecords_progress) ? "" : "%"}   </span>
                                             <div style={{
                                                ...(!isNaN(x?.resultProgress?.medicalRecords_progress) && { width: x?.resultProgress?.medicalRecords_progress + '%' }),
                                                height: '1.9rem',
                                                backgroundColor: '#18479a',
                                                borderRadius: '7px',
                                                color: 'white',
                                                textAlign: 'center',
                                                fontSize: 12,
                                                // paddingTop: 8
                                             }} ></div>

                                          </div>
                                       </RenderIf>
                                    </td>
                                    <td>
                                       <RenderIf shouldRender={x?.resultProgress?.preMedicalRecords_progress}>

                                          <div style={{ width: '9rem', height: 32, border: '2px solid black', color: 'black', borderRadius: '10px', textAlign: 'center' }}>
                                             <span style={{
                                                position: 'absolute', marginLeft: x?.resultProgress?.preMedicalRecords_progress?.length >= 2 ? TextPositonService.preMedicalprogress(x?.resultProgress?.preMedicalRecords_progress?.split(" ")) : '-15px',
                                                color: ColorService.preMedicalProgress(Array.isArray(x?.resultProgress?.preMedicalRecords_progress) ? x?.resultProgress?.preMedicalRecords_progress.split(' ') : x?.resultProgress?.preMedicalRecords_progress)
                                             }}>
                                                {x?.resultProgress?.preMedicalRecords_progress}{isNaN(x?.resultProgress?.preMedicalRecords_progress) ? "" : "%"}   </span>
                                             <div style={{
                                                width: x?.resultProgress?.preMedicalRecords_progress === "Refining" ? '100%' : x?.resultProgress?.preMedicalRecords_progress + '%',
                                                height: '1.9rem',
                                                backgroundColor: '#18479a',
                                                borderRadius: '7px',
                                                color: 'white',
                                                textAlign: 'center',
                                                fontSize: 12,
                                                // paddingTop: 8
                                             }} ></div>

                                          </div>
                                       </RenderIf>
                                    </td>
                                    <td>
                                       <RenderIf shouldRender={x?.resultProgress?.medicalBills_progress}>
                                          {/* <Spinner name="cube-grid" style={{ width: 100, height: 50, color: '#18479a', border: '3px solid #18479a', borderRadius: '5px' }} /> */}

                                          <div style={{ width: '9rem', height: 32, border: '2px solid black', color: 'black', borderRadius: '10px', textAlign: 'center', }}>
                                             <span style={{

                                                position: 'absolute', marginLeft: x?.resultProgress?.medicalBills_progress?.length >= 2 ? TextPositonService.medicalBillProgress(x?.resultProgress?.medicalBills_progress?.split(' ')) : '-20px',
                                                color: ColorService.medicalBillProgress(Array.isArray(x?.resultProgress?.medicalBills_progress) ? x?.resultProgress?.medicalBills_progress.split(' ') : x?.resultProgress?.medicalBills_progress)
                                             }}>
                                                {x?.resultProgress?.medicalBills_progress}{isNaN(x?.resultProgress?.medicalBills_progress) ? "" : "%"}</span>
                                             <div style={{
                                                width: x?.resultProgress?.medicalBills_progress + '%',
                                                height: '1.9rem',
                                                backgroundColor: '#18479a',
                                                borderRadius: '7px',
                                                color: 'white',
                                                textAlign: 'center',
                                                fontSize: 12,
                                                // paddingTop: 8
                                             }} ></div>
                                          </div>
                                       </RenderIf>
                                    </td>
                                    <td>
                                       <RenderIf shouldRender={x?.resultProgress?.demandLetter_progress}>
                                          {/* <Spinner name="cube-grid" style={{ width: 100, height: 50, color: '#18479a', border: '3px solid #18479a', borderRadius: '5px' }} /> */}

                                          <div style={{ width: '9rem', height: 32, border: '2px solid black', color: 'black', borderRadius: '10px', textAlign: 'center', }}>
                                             <span style={{

                                                position: 'absolute', marginLeft: x?.resultProgress?.demandLetter_progress?.length >= 2 ? TextPositonService.demandLetterProgress(x?.resultProgress?.demandLetter_progress) : '-20px',
                                                color: ColorService.demandLetterProgress(x?.resultProgress?.demandLetter_progress)
                                             }}>
                                                {x?.resultProgress?.demandLetter_progress}{isNaN(x?.resultProgress?.demandLetter_progress) ? "" : "%"}</span>
                                             <div style={{
                                                ...(!isNaN(x?.resultProgress?.demandLetter_progress) && { width: x?.resultProgress?.demandLetter_progress + '%' }),
                                                height: '1.9rem',
                                                backgroundColor: '#18479a',
                                                borderRadius: '7px',
                                                color: 'white',
                                                textAlign: 'center',
                                                fontSize: 12,
                                                // paddingTop: 8
                                             }} ></div>
                                          </div>
                                       </RenderIf>
                                    </td>
                                 </>
                           }
                           <RenderIf shouldRender={
                              x?.resultProgress?.demandLetter_progress !== "Successful"}>
                              <td>
                                 <button class="btn btn-viewPdf1" onClick={async (e) => { openDeletCaseeModal(x._id) }}>
                                    <i className='fa-solid fa-trash' ></i>
                                 </button>
                              </td>
                           </RenderIf>

                           <RenderIf shouldRender={isCaseGeneratedSuccessfully && isDemandGeneratedSuccessfully}>
                              <td >
                                 <button class="btn btn-viewPdf1" onClick={() => { downloadDemandDraft({ caseId: x._id, caseName: x?.detailsInput.liability.caseName }) }} ><i className='fa-solid fa-download' ></i></button>
                              </td>
                           </RenderIf>
                           <td>
                              <div style={{ width: '20px' }}>
                                 <Formik
                                    initialValues={{
                                       typeOfDemand: ''
                                    }}
                                    // validationSchema={validationSchema}
                                    onSubmit={(values) => {

                                       console.log('Form data', values);

                                    }}
                                 >
                                    {({ setFieldValue, submitForm }) => (
                                       <Form>
                                          <div>
                                             <Field
                                                as="select"
                                                name="type"
                                                style={{ width: '10.5rem', height: '2.5rem', border: 'none', backgroundColor: '#F2F2F2', borderRadius: '4px' }}
                                                onChange={(e) => {
                                                   const value = e.target.value;
                                                   changeTypeOfDemandDraftHandler(value)
                                                }}

                                             >{Constants.Dropdowns.DemandType &&
                                                Constants.Dropdowns.DemandType.length &&
                                                Constants.Dropdowns.DemandType.map((option, index) => (
                                                   <option key={index} value={option.value} >
                                                      {option.text}
                                                   </option>
                                                ))}
                                             </Field>
                                          </div>
                                       </Form>
                                    )}
                                 </Formik>
                              </div>
                           </td>
                           <td>
                              <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                 <RenderIf shouldRender={isCaseFailed && !isDemandGeneratedSuccessfully}>
                                    <AiOutlineCloseCircle size={40} color="red" onClick={() => openModal(x._id)} />
                                 </RenderIf>
                                 <RenderIf shouldRender={isCaseGeneratedSuccessfully && isDemandGeneratedSuccessfully}>
                                    <BsCheck2Circle size={40} color="green" />
                                 </RenderIf>
                              </span>
                           </td>

                        </tr>
                     })}
                  </tbody>
               </table>
               <div class="dataTables_info" id="myTable_info" role="status" aria-live="polite">Showing 1 to {showEntries} of {totalCount} entries</div>

               {totalPages > 1 && <div class="dataTables_paginate paging_simple_numbers" id="myTable_paginate">
                  <ReactPagination
                     activePage={currentPage}
                     totalItemCount={totalPages}
                     onChange={handlePageChange}
                  />
               </div>}
            </>}
         </div>
      </RenderIf >
      <Dialog
         isModalOpen={isDeleteCaseModalOpen}
         onToggleDialog={(isDialogOpen) => onToggleDeleteCaseDialog(isDialogOpen)}
         mode="small-half"
         isCloseIconHidden={false}
      >
         <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
            <h3 className="title">Are you sure you want to proceed?</h3>
            {/* <h4>{caseId}</h4> */}
         </div>
         <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
            <button class="btn btn-viewPdf1" style={{ width: '6rem', height: '3.5rem' }} onClick={() => deleteFreezedCase()}>Yes</button>
            <button class="btn btn-viewPdf1" style={{ marginLeft: '1rem' }} onClick={() => setIsDeleteCaseModalOpen(false)}>No</button>
         </div>
      </Dialog >

      {addCaseIsLoading && <Dialog
         onToggleDialog={(isDialogOpen) => {
         }}
         isModalOpen={addCaseIsLoading}
         mode="small-half"
         isCloseIconHidden={false}
      >
         <div style={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
            <h3 className="title">Please wait while files are uploading...</h3>
         </div>
         <LinearProgressWithLabel style={{ marginTop: '2rem' }} value={caseFileUploadProgress} />
      </Dialog>}

      <Dialog
         isModalOpen={isModalOpen}
         onToggleDialog={(isDialogOpen) => onToggleDialog(isDialogOpen)}
         mode="full"
         isCloseIconHidden={false}
      >
         <h2 className="title">Error Log</h2>
         <div style={{ overflow: 'scroll', height: '45rem' }}>
            <table id="myTable" class="display dataTable no-footer" aria-describedby="myTable_info">
               <thead >
                  <tr >
                     <th style={{ width: "200px" }}>ErrorId</th>
                     <th style={{ width: "200px" }}>CaseId</th>
                     <th style={{ width: "200px" }}>createdOn</th>
                     <th style={{ width: "200px" }}>Error Code</th>
                     <th style={{ width: "300px" }}>Error Description </th>

                  </tr>
               </thead>
               <tbody>
                  {
                     errorLogData?.map((values) => {
                        return <tr>
                           {/* userId, caseId, errorCode, errorDescrition, createDate */}
                           <td>{values._id}</td>
                           <td>{values.caseId}</td>
                           <td>{values.createDate}</td>
                           <td>{values.errorCode}</td>
                           <td>{values.errorDescrition}</td>
                        </tr>
                     })
                  }
               </tbody>
            </table>
         </div>
      </Dialog >
   </ div >

}


export default Cases