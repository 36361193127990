import React, { useState, useEffect } from "react";

import { UserService } from "../../../api-services";
import ReactPagination from "../../../components/common/Pagination";
import useSortableData from "../../../components/customHook/SortableTable";
import { useNavigate } from "react-router-dom";
import AddCompaniesForm from "./components/AddCompaniesForm";
import { RenderIf } from "../../../components";
import LogoutService from "../../../api-services/LogoutService";
import { BsPencil } from 'react-icons/bs';
import { FaPaperPlane } from 'react-icons/fa';

import EditCompaniesForm from "./components/EditCompaniesForm";
import { parseAndFormatUtcDate } from "../../../Utils";
import { debounce } from "../../../utils/helper";
import Constants from "../../../Constants";
import useAxios from "../../../hooks/useAxios";
import { toaster } from "../../../utils/toast";
const Companies = () => {

   //    const [isAddUserDialogOpened, setIsAddUserDialogOpened] = useState(false);
   const [isSubmittingForm, setIsSubmittingForm] = useState(false);
   const [companiesList, setcompaniesList] = useState([]);
   const { items: listCompanies, requestSort, sortConfig } = useSortableData(companiesList, { direction: "sorting_asc", key: "firstName.lastName" });
   const [currentPage, setCurrentPage] = useState(1);
   const [totalCount, settotalCount] = useState("");
   const [totalPages, settotalPages] = useState(0);
   const [searchText, setsearchText] = useState("")
   const [showEntries, setshowEntries] = useState(10)
   const [selectedUser, setSelectedUser] = useState(null);
   const { getData: geCompaniesFetchData } = useAxios()

   const navigate = useNavigate();

   useEffect(() => {
      if (!isSubmittingForm) {
         getCompanies();
      }
   }, [currentPage, searchText, showEntries, isSubmittingForm])

   const getCompanies = async () => {
      const url = `${Constants.ApiUrl.companies.getCompanies}?pageNumber=${currentPage}&limit=${showEntries}&searchText=${searchText}`;
      const { success, data, message } = await geCompaniesFetchData(url);
      if (success) {
         const { companies, totalCount, totalPages } = data;
         setcompaniesList(companies);
         settotalPages(totalPages);
         settotalCount(totalCount)
      } else {
         toaster({ message, success });
      }
   }

   const onAddEditUserSubmit = (isAdd, formData, setErrors) => {

      setIsSubmittingForm(true);
      if (isAdd) {
         addUser(formData, setErrors);
         return;
      }
   }
   const onCloseform = () => {
      setTimeout(() => {
         LogoutService.Logout().then((res) => {
            console.log(res, "res in  master databse switch");
            getCompanies();

         }).catch(err => {

         })
      }, 5000);

      setIsSubmittingForm(false);
      setSelectedUser(false);

   }

   const addUser = (formData, setErrors) => {

      UserService.AddUser(formData).then(resp => {
         onFormSubmitted();
      }).catch(err => {
         setErrors({ ...err?.response?.data?.message })
         setIsSubmittingForm(false);
         //  onFormSubmitted();
      })

   }

   const onFormSubmitted = () => {
      setIsSubmittingForm(false);
   }
   const handlePageChange = (event, pageNumber) => {
      setCurrentPage(pageNumber);
   };

   const getClassNamesFor = (name) => {
      if (!sortConfig) {
         return;
      }
      return sortConfig.key === name ? sortConfig.direction : undefined;
   };

   const handleEdit = (user) => {
      setSelectedUser(user);
   };
   const launchCompanyWebsite = () => {
      localStorage.clear();
      navigate('/account/login')
  };

   const handleSearchInputChange = (e) => {
      setsearchText(e.target.value)
   };

   const debouncedHandleSearchChange = debounce(handleSearchInputChange, 500);


   return <div className="listing">
      <RenderIf shouldRender={!isSubmittingForm && !selectedUser}>
         <div class="title d-flex justify-content-between align-items-center">
            <span>List of all Comapnies</span>
            <button class="btn btn-theme" onClick={() => setIsSubmittingForm(true)}><i class="fa-solid fa-plus me-2"></i> Add Companies</button>

         </div>
      </RenderIf>
      <RenderIf shouldRender={isSubmittingForm}>

         <AddCompaniesForm
            onSubmit={onAddEditUserSubmit}
            isSubmittingForm={onCloseform}
         />
      </RenderIf>
      <RenderIf shouldRender={!isSubmittingForm && !selectedUser}>

         <div id="myTable_wrapper" class="dataTables_wrapper no-footer">
            <div class="dataTables_length" id="myTable_length">
               <label>
                  Show
                  <select value={showEntries} onChange={(e) => setshowEntries(parseInt(e.target.value))} name="myTable_length" aria-controls="myTable" class="">
                     <option value="10">10</option>
                     <option value="25">25</option>
                     <option value="50">50</option>
                     <option value="100">100</option>
                  </select>
                  entries
               </label>
            </div>
            <div id="myTable_filter" class="dataTables_filter"><label>Search:<input onChange={debouncedHandleSearchChange} type="search" class="" placeholder="" aria-controls="myTable" /></label></div>

            <table id="myTable" class="display dataTable no-footer" aria-describedby="myTable_info">
               <thead>
                  <tr>
                     <th onClick={() => requestSort('firstName.lastName')} className={`sorting ${getClassNamesFor("firstName.lastName")}`} tabindex="0" aria-controls="myTable" rowspan="1" colspan="1" aria-sort={sortConfig?.direction === "sorting_asc" ? "ascending" : "descending"} aria-label={sortConfig?.direction === "sorting_asc" ? "ascending" : "descending"} style={{ width: "370.875px" }}>Company Name</th>
                     <th onClick={() => requestSort('firstName.lastName')} className={`sorting ${getClassNamesFor("firstName.lastName")}`} tabindex="0" aria-controls="myTable" rowspan="1" colspan="1" aria-sort={sortConfig?.direction === "sorting_asc" ? "ascending" : "descending"} aria-label={sortConfig?.direction === "sorting_asc" ? "ascending" : "descending"} style={{ width: "370.875px" }}>First Name</th>
                     <th onClick={() => requestSort('firstName.lastName')} className={`sorting ${getClassNamesFor("firstName.lastName")}`} tabindex="0" aria-controls="myTable" rowspan="1" colspan="1" aria-sort={sortConfig?.direction === "sorting_asc" ? "ascending" : "descending"} aria-label={sortConfig?.direction === "sorting_asc" ? "ascending" : "descending"} style={{ width: "370.875px" }}>Last Name</th>
                     <th onClick={() => requestSort('email')} className={`sorting ${getClassNamesFor("email")}`} tabindex="0" aria-controls="myTable" rowspan="1" colspan="1" aria-sort={sortConfig?.direction === "sorting_asc" ? "ascending" : "descending"} aria-label={sortConfig?.direction === "sorting_asc" ? "ascending" : "descending"} style={{ width: "310.038px" }}>Contact Email Address</th>
                     <th onClick={() => requestSort('mobileNumber')} className={`sorting ${getClassNamesFor("mobileNumber")}`} tabindex="0" aria-controls="myTable" rowspan="1" colspan="1" aria-sort={sortConfig?.direction === "sorting_asc" ? "ascending" : "descending"} aria-label={sortConfig?.direction === "sorting_asc" ? "ascending" : "descending"} style={{ width: "250.475px" }} >Company Phone Number</th>
                     <th onClick={() => requestSort('firstName')} className={`sorting ${getClassNamesFor("firstName")}`} tabindex="0" aria-controls="myTable" rowspan="1" colspan="1" aria-sort={sortConfig?.direction === "sorting_asc" ? "ascending" : "descending"} aria-label={sortConfig?.direction === "sorting_asc" ? "ascending" : "descending"} style={{ width: "219.012px" }}>Company Address</th>
                     <th onClick={() => requestSort('firstName')} className={`sorting ${getClassNamesFor("firstName")}`} tabindex="0" aria-controls="myTable" rowspan="1" colspan="1" aria-sort={sortConfig?.direction === "sorting_asc" ? "ascending" : "descending"} aria-label={sortConfig?.direction === "sorting_asc" ? "ascending" : "descending"} style={{ width: "219.012px" }}>Subscription Renewal Date</th>
                     <th onClick={() => requestSort('firstName')} className={`sorting ${getClassNamesFor("firstName")}`} tabindex="0" aria-controls="myTable" rowspan="1" colspan="1" aria-sort={sortConfig?.direction === "sorting_asc" ? "ascending" : "descending"} aria-label={sortConfig?.direction === "sorting_asc" ? "ascending" : "descending"} style={{ width: "219.012px" }}>Edit</th>
                     <th onClick={() => requestSort('firstName')} className={`sorting ${getClassNamesFor("firstName")}`} tabindex="0" aria-controls="myTable" rowspan="1" colspan="1" aria-sort={sortConfig?.direction === "sorting_asc" ? "ascending" : "descending"} aria-label={sortConfig?.direction === "sorting_asc" ? "ascending" : "descending"} style={{ width: "219.012px" }}>Launch Company</th></tr>
               </thead>
               <tbody>
                  {
                     listCompanies?.map((user, i) => (
                        <tr class="odd">
                           <td class="user-name sorting_1">
                              {/* <img src="images/user.png" class="img-fluid me-2" /> */}
                              <span>{user?.companyName}</span>
                           </td>
                           <td class="user-name sorting_1">
                              <span>{user?.firstName}</span>
                           </td>
                           <td class="user-name sorting_1">
                              <span>{user?.lastName}</span>
                           </td>
                           <td>{user?.contactEmail}</td>
                           <td>{user?.companyPhoneNumber}</td>
                           <td>{user?.companyAddress}</td>
                           <td>{parseAndFormatUtcDate(user?.subscription?.subscriptionRenewalDate)}</td>
                           <td>
                              <button className="btn btn-viewPdf1" style={{ width: '5rem', height: '3rem' }} onClick={() => handleEdit(user)}>
                                 <BsPencil />
                              </button>
                           </td>
                           <td>
                              <button className="btn btn-viewPdf1" style={{ width: '5rem', height: '3rem' }} onClick={() => launchCompanyWebsite()}>
                              <FaPaperPlane />
                              </button>
                           </td>
                        </tr>
                     ))

                  }
               </tbody>
            </table>
            <div class="dataTables_info" id="myTable_info" role="status" aria-live="polite">Showing 1 to {showEntries} of {totalCount} entries</div>
            {totalPages > 1 && <div class="dataTables_paginate paging_simple_numbers" id="myTable_paginate">
               <ReactPagination
                  activePage={currentPage}
                  totalItemCount={totalPages}
                  onChange={handlePageChange}
               />
            </div>}
            
         </div>
      </RenderIf>
      <RenderIf shouldRender={selectedUser}>
         <EditCompaniesForm
            selectedUser={selectedUser}
            onSubmit={(formData, setErrors) => onAddEditUserSubmit(false, formData, setErrors)}
            isSubmittingForm={onCloseform}
         />
      </RenderIf>


   </div>
}

export default Companies;