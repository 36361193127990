import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DotLoader from "react-spinners/BeatLoader";
import { RenderIf } from '../../../../components';
import DamagesAnalysisForm from './DamagesAnalysisForm';
import InjuriesAnalysisForm from './InjuriesAnalysisForm';
import LiabilityAnalysisForm from './LiabilityAnalysisForm';
import Progressbar from './Progressbar';
import socketio from "socket.io-client";
import Constants from '../../../../Constants';
import AppConfig from '../../../../AppConfig';
// import { Document, Page } from 'pdfjs-dist';
import { CaseService } from '../../../../api-services';
import { Document, Page, pdfjs } from 'react-pdf';
import Cases from '../Cases';
// import { PDFViewer, Text, View, Document, Page, StyleSheet } from '@react-pdf/renderer';
//import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack5';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

// console.log(pdfjs.version)

const AddCaseForm = ({ addCases }) => {
  const [formSteps, setFormSteps] = useState(0);
  const [formDataPayload, setFormDataPayload] = useState({});
  const [policeReport, setPoliceReport] = useState([]);
  const [accidentScenesPhotos, setAccidentScenesPhotos] = useState('')
  const [bodyInjuryFiles, setBodyInjuryFiles] = useState('')
  const [injuriesPhotos, setInjuriesPhotos] = useState('');
  const [medicalRecordsPdf, setMedicalRecordsPdf] = useState('')
  const [medicalBillsfile, setMedicalBillFile] = useState('')
  const [incomeDocumentPhotos, setIncomeDocumentPhotos] = useState('')
  const [isGeneratingPdf, setIsGeneratingPdf] = useState('');
  const [caseName, setCaseName] = useState('');
  const navigate = useNavigate();
  let medicalBillPdf = [];





  const onPoliceReportUpload = (file) => {

    setPoliceReport(file);
    console.log("ploice", file)
  }

  const onAccidentSecnesUpload = (file) => {
    setAccidentScenesPhotos(file)
    console.log("accident", file)
  }

  const onInjuriesPhotosUpload = (file) => {
    setInjuriesPhotos(file)


    console.log("injury", file)
  }


  const onMedicalRecordsUpload = (file) => {

    setMedicalRecordsPdf(file)
    // for (var i = 0; i < file.length; i++) {
    //   medicalBillPdf.push(file[i])
    // }
    // console.log("medical", file)
  }
  console.log(medicalBillPdf);


  const onMedicalBillUpload = (file) => {
    setMedicalBillFile(file)
    console.log("medicalBill", file)
  }

  const onIncomeDocmentUpload = (file) => {
    setIncomeDocumentPhotos(file);
    console.log("incomeDocument", file)
  }


  const incrementFormStep = () => {
 
    setFormSteps(formSteps + 1);
  }

  const Decrement = () => {
     
    setFormSteps(formSteps - 1)
  }

  const finalFormStepNumber = 2
  const onSubmitClick = (formData) => {
    formData = { ...formDataPayload, ...formData }

    setFormDataPayload(formData);
    if (formSteps === finalFormStepNumber) {
      setFormSteps(0);
      processPromptInputContentOnServer(formData);
    }
    else {
      incrementFormStep();
    }

  }

  const processPromptInputContentOnServer = (data) => {

    setIsGeneratingPdf(true);

    const formsPayload = {
      data,
      policeReport,
      accidentScenesPhotos,
      bodyInjuryFiles,
      injuriesPhotos,
      medicalRecordsPdf,
      medicalBillsfile,
      incomeDocumentPhotos
    }

    addCases(formsPayload)
    navigate('/cases', { state: { formsPayload } })
  }
  console.log(formSteps);

  const fromStepValue = (value) => {
    setFormSteps(value)
    console.log(value)
  }

  return (
    <>
      <RenderIf shouldRender={!isGeneratingPdf} >
        <Progressbar formSteps={formSteps} fromStepValue={fromStepValue} />
      </RenderIf>
      <RenderIf shouldRender={formSteps === 0 && !isGeneratingPdf}>
        <LiabilityAnalysisForm
          onSubmit={onSubmitClick}
          onPoliceReportUpload={onPoliceReportUpload}
          onAccidentSecnesUpload={onAccidentSecnesUpload}
          onBodyInjuryUpload={setBodyInjuryFiles}
          caseName={caseName} // Pass caseName and setCaseName to LiabilityAnalysisForm
          setCaseName={setCaseName}
        />
      </RenderIf>
      <RenderIf shouldRender={formSteps === 1 && !isGeneratingPdf}>
        <InjuriesAnalysisForm
          onSubmit={onSubmitClick}
          stepDecrement={Decrement}
          onMedicalRecordsUpload={onMedicalRecordsUpload}
        />
      </RenderIf>
      <RenderIf shouldRender={formSteps === 2 && !isGeneratingPdf}>
        <DamagesAnalysisForm
          onSubmit={onSubmitClick}
          stepDecrement={Decrement}
          onInjuriesPhotosUpload={onInjuriesPhotosUpload}
          onMedicalBillUpload={onMedicalBillUpload}
          onIncomeDocmentUpload={onIncomeDocmentUpload}
        />
      </RenderIf>

      <RenderIf shouldRender={isGeneratingPdf}>
      </RenderIf>



    </>
  )



}


export default AddCaseForm;