import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, RenderIf, TextInput, } from '../../../../components';
import Constants from '../../../../Constants';
import { getNestedValue } from '../../../../utils/helper';
import { storeInjuryAnalysisEstern } from '../../../../redux/actions/liabilty';


const InjuriesAnalysisForm = ({ onSubmit, stepDecrement }) => {
    const dispatch = useDispatch();
    const injuryData = useSelector(state => state.injuryData.injuryData);
    let [recordArrayLength, setRecordArrayLength] = useState({
        postAccident: 5,
        preAccident: 5
    })

    const initialValues = {
        postAccident: injuryData?.postAccident || [
            {
                providerName: '',
                medicalRecords: [],
                medicalType: '',
                medicalBills: []
            }
        ],

        preAccident: injuryData?.preAccident || [
            {
                providerName: '',
                medicalRecords: [],
                medicalType: ''
            }
        ]
    };

    const addFile = ({ formik, name, e }) => {
        const files = Array.from(e.target.files);

        if (!files?.every(file => file.size > 0)) {
            const dialog = document.createElement('dialog');
            dialog.innerHTML = `
            <div style="color: #18479a;">
                <h2>Warning!</h2>
                <p>Some files uploaded contain 0 bytes.</p>
                <p>Please download the file onto your server or computer, and reupload the file to AI Demand Pro</p>
                <button type='button' style="background-color: #18479a; color: white; padding: 8px 16px; border: none; border-radius: 4px; cursor: pointer;" onclick="this.closest('dialog').remove()">OK</button>
            </div>
        `;
            document.body.appendChild(dialog);
            dialog.showModal();
            e.target.value = '';
            return
        }

        const prevfiles = getNestedValue(formik.values, name, [])
        formik.setFieldValue(name, [...prevfiles, ...files]);
    }

    const deleteFile = ({ formik, name, index }) => {
        const currentFiles = [...getNestedValue(formik.values, name, [])];
        currentFiles.splice(index, 1);
        formik.setFieldValue(name, currentFiles);
    }

    const removeRow = ({ record, formik, providerLevelIndex }) => {
        const prevRecords = getNestedValue(formik.values, `${record}`, [])
        prevRecords.splice(providerLevelIndex, 1)
        formik.setFieldValue(record, [...prevRecords]);

        setRecordArrayLength({
            ...recordArrayLength,
            [record]: recordArrayLength[record] - 1
        })
        
    }

    const addRow = ({ record }) => {
        setRecordArrayLength({
            ...recordArrayLength,
            [record]: recordArrayLength[record] + 1
        })
    }

    const onFormikSubmitHandler = (values) => {
        onSubmit({ injury: values });
        dispatch(storeInjuryAnalysisEstern(values));
    }

    return (
        <>
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                onSubmit={onFormikSubmitHandler}
            >
                {(formik) => (
                    <Form>
                        <div className="add-form p-0">
                            <div className="card">
                                {
                                    Object.keys(formik.values).map((record) => {

                                        return (
                                            <div className='mt-4' key={`${record}_key`}>
                                                {record === 'postAccident' && <h2>Post-Accident Medical Providers</h2>}
                                                {record === 'preAccident' && <h2>Pre-Accident Medical Providers</h2>}
                                                {Array(recordArrayLength[record]).fill(0).map((_, providerLevelIndex) => {
                                                    const medicalRecords = formik?.values?.[record]?.[providerLevelIndex]?.medicalRecords
                                                    const medicalBills = formik?.values?.[record]?.[providerLevelIndex]?.medicalBills

                                                    return (
                                                        <>
                                                            <div className="row" key={`${formik?.values?.[record]?.[providerLevelIndex]}_key`}>
                                                                <div className="col-md-3">
                                                                    <div className='mt-2'>
                                                                        <Field
                                                                            name={`${record}.${providerLevelIndex}.providerName`}
                                                                            placeholder="Write Medical Provider Name"
                                                                            {...(!providerLevelIndex && { label: "Medical Provider / Facitlity" })}
                                                                            component={TextInput}
                                                                        />
                                                                    </div>
                                                                    {/* <span style={{ color: 'red' }}>{error.medicalProviderNames_0}</span> */}
                                                                </div>
                                                                <div className="col-md-3" style={{ paddingTop: '2px', paddingLeft: '5px' }}>
                                                                    <div className='mt-2' >
                                                                        {!providerLevelIndex && <><label>Upload Provider Medical Records <i className="fa-solid fa-upload" style={{ paddingLeft: '11.8rem' }}></i>
                                                                        </label><br></br></>}
                                                                        <RenderIf shouldRender={medicalRecords?.length}>
                                                                            <div style={{ marginLeft: '26rem' }}>
                                                                                <i className='fa-solid fa-trash' style={{ position: 'absolute', width: '20px', height: '20px', paddingTop: '1.5rem' }} onClick={(e) => {
                                                                                    deleteFile({ name: `${record}.${providerLevelIndex}.medicalRecords`, formik, index: 0 })
                                                                                }
                                                                                }>

                                                                                </i>
                                                                            </div>
                                                                        </RenderIf>

                                                                        <label htmlFor={`fileInputMedicalRecords_${record}_${providerLevelIndex}`} style={{ cursor: 'pointer' }}>
                                                                            <i className="fa-solid fa-plus" style={{ position: 'absolute', paddingTop: '1.3rem', paddingLeft: '1rem' }}></i>
                                                                            <div className="fileInputStyle mt-2" style={{ backgroundColor: '#F2F2F2', borderRadius: '5px', height: '3rem', width: '28rem' }}>
                                                                                <div style={{ textAlign: 'center', height: '1px', paddingTop: '10px' }}>
                                                                                    {medicalRecords?.length ? medicalRecords?.[0]?.name : 'Upload Medical Record'}
                                                                                </div>
                                                                            </div>
                                                                        </label>

                                                                        <Field
                                                                            type="file"
                                                                            accept="application/pdf"
                                                                            id={`fileInputMedicalRecords_${record}_${providerLevelIndex}`}
                                                                            name={`fileInputMedicalRecords_${record}_${providerLevelIndex}`}
                                                                            style={{ display: 'none' }}
                                                                            multiple
                                                                            onChange={(e) => {
                                                                                addFile({ e, name: `${record}.${providerLevelIndex}.medicalRecords`, formik })
                                                                            }}
                                                                        />
                                                                    </div>

                                                                    <RenderIf shouldRender={medicalRecords?.length}>
                                                                        {
                                                                            medicalRecords?.slice(1)?.map((v, index) => (
                                                                                <div key={`${record}.${providerLevelIndex}.${index}.medicalRecords_key`}>
                                                                                    <div style={{ marginLeft: '26rem' }}>
                                                                                        <i className='fa-solid fa-trash' style={{ position: 'absolute', width: '20px', height: '20px', paddingTop: '1.5rem' }} onClick={(e) => {
                                                                                            deleteFile({ name: `${record}.${providerLevelIndex}.medicalRecords`, formik, index: index + 1 })
                                                                                        }}></i>
                                                                                    </div>
                                                                                    <div className='mt-2' key={`${index}`} style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', width: '28rem', height: '3rem', backgroundColor: '#F2F2F2', }}>
                                                                                        <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginTop: '0.7rem', }}>{v?.name}</div>
                                                                                    </div>
                                                                                </div>
                                                                            ))

                                                                        }
                                                                    </RenderIf >

                                                                </div>
                                                                <div className="col-md-3 mt-2" style={{ paddingTop: '2px', paddingLeft: '5px' }}>
                                                                    <Field
                                                                        name={`${record}.${providerLevelIndex}.medicalType`}
                                                                        {...(!providerLevelIndex && { label: "Medical Record Type" })}
                                                                        component={Dropdown}
                                                                        defaultOption="Select Type"
                                                                        options={Constants.Dropdowns.FileType}
                                                                    />
                                                                </div>

                                                                {record === 'postAccident' && <div className="col-md-3" style={{ paddingTop: '2px', paddingLeft: '5px' }}>
                                                                    <div className='mt-2'>
                                                                        {!providerLevelIndex && <><label style={{ width: '30rem' }}>Upload Medical Bills <i className="fa-solid fa-upload" style={{ paddingLeft: '17.4rem' }} ></i>
                                                                        </label><br></br></>}

                                                                        <RenderIf shouldRender={medicalBills?.length}>
                                                                            <div style={{ marginLeft: '26rem' }}>
                                                                                <i className='fa-solid fa-trash' style={{ position: 'absolute', width: '20px', height: '20px', paddingTop: '1.5rem' }} onClick={(e) => { deleteFile({ name: `postAccident.${providerLevelIndex}.medicalBills`, formik, index: 0 }) }}></i>
                                                                            </div>
                                                                        </RenderIf>

                                                                        <div className='' style={{ display: 'flex' }}>

                                                                            <label htmlFor={`fileInputBill${providerLevelIndex}`} style={{ cursor: 'pointer' }}>
                                                                                <i className="fa-solid fa-plus" style={{ position: 'absolute', paddingTop: '1.3rem', paddingLeft: '1rem' }}></i>
                                                                                <div className="fileInputStyle mt-2" style={{ backgroundColor: '#F2F2F2', borderRadius: '5px', height: '3rem', width: '28rem' }}>
                                                                                    <div style={{ textAlign: 'center', height: '1px', paddingTop: '10px' }}>
                                                                                        {medicalBills?.length ? medicalBills?.[0]?.name : 'Upload Medical Bill'}
                                                                                    </div>
                                                                                </div>
                                                                            </label>

                                                                            <Field
                                                                                type="file"
                                                                                accept="application/pdf"
                                                                                id={`fileInputBill${providerLevelIndex}`}
                                                                                name={`fileInputBill${providerLevelIndex}`}
                                                                                style={{ display: 'none' }}
                                                                                multiple
                                                                                onChange={(e) => {
                                                                                    addFile({ e, name: `postAccident.${providerLevelIndex}.medicalBills`, formik })
                                                                                }}
                                                                            />

                                                                            {providerLevelIndex === 4 && <div className='add-icon' style={{ marginLeft: '0.6rem' }}>
                                                                                <button type='button' className='add-btn-style' onClick={(e) => addRow({ record })}><i className="fa-solid fa-plus"></i></button>
                                                                            </div>}

                                                                            {providerLevelIndex > 4 && <div className='add-icon' style={{ marginLeft: '1rem' }}>
                                                                                <button type='button' className='add-btn-style' onClick={(e) => removeRow({ record, formik, providerLevelIndex })}><i className="fa-solid fa-minus"></i></button>
                                                                            </div>}

                                                                        </div>

                                                                        <RenderIf shouldRender={medicalBills?.length}>
                                                                            {
                                                                                medicalBills?.slice(1)?.map((record, index) => (
                                                                                    <div key={`postAccident.${providerLevelIndex}.${index}.medicalBills_key`}>
                                                                                        <div style={{ marginLeft: '26rem' }}>
                                                                                            <i className='fa-solid fa-trash' style={{ position: 'absolute', width: '20px', height: '20px', paddingTop: '1.5rem' }} onClick={(e) => {
                                                                                                deleteFile({ name: `postAccident.${providerLevelIndex}.medicalBills`, formik, index: index + 1 })
                                                                                            }}></i>
                                                                                        </div>
                                                                                        <div className='mt-2' key={`${index}`} style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', width: '28rem', height: '3rem', backgroundColor: '#F2F2F2', }}>
                                                                                            <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginTop: '0.7rem', }}>{record?.name}</div>
                                                                                        </div>
                                                                                    </div>
                                                                                ))
                                                                            }
                                                                        </RenderIf >
                                                                    </div>

                                                                </div>}
                                                                {record === 'preAccident' && <div className="col-md-3 align-righ" style={{ paddingTop: '2px', paddingLeft: '5px' }}>

                                                                    {providerLevelIndex === 4 && <button type='button' className='preMedicalProvider-add-icon' onClick={(e) => addRow({ record })} style={{ backgroundColor: '#18479a' }}><i className="fa-solid fa-plus"></i></button>}

                                                                    {providerLevelIndex > 4 && <button type='button' className='preMedicalProvider-add-icon' onClick={(e) => removeRow({ record, formik, providerLevelIndex })} style={{ backgroundColor: '#18479a' }}><i className="fa-solid fa-minus"></i></button>}

                                                                </div>}
                                                            </div>
                                                        </>
                                                    )
                                                })}
                                            </div>
                                        )
                                    })
                                }

                            </div>
                        </div>

                        <div className="btns text-center mt-4">

                            <button type='button' className="btn-theme btn-outline me-3" onClick={async () => {
                                dispatch(storeInjuryAnalysisEstern(formik.values));
                                stepDecrement();
                            }}>Previous</button>

                            <button className="btn-theme" type="submit">Next</button>
                        </div>

                    </Form >
                )
                }
            </Formik >
        </>
    )

}

export default InjuriesAnalysisForm;