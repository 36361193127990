import React, { useState, useEffect, useRef, useCallback } from "react";
import '../style.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaCalendarAlt } from 'react-icons/fa';
import Graph from "./components/Graph";
import useAxios from "../../../hooks/useAxios";
import Constants from "../../../Constants";
import { toaster } from "../../../utils/toast";
import XLoader from "../../../components/common/XLoader";
import { useSelector } from "react-redux";
import { parseAndFormatUtcDate } from "../../../Utils";


const Dashboard = () => {
    const currentDate = new Date();
    const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

    const [userAnalyticDataPoint, setUserAnalyticDataPoint] = useState([]);
    const [monthlyAnalyticDataPoint, setMonthlyAnalyticDataPoint] = useState([]);
    const [from, setFrom] = useState(startOfMonth);
    const [to, setTo] = useState(currentDate);
    const fromDatePickerRef = useRef(null);
    const toDatePickerRef = useRef(null);
    const [averageTimeTaken, setAverageTimeTaken] = useState("");
    const [totalNumCases, setTotalNumCases] = useState("");
    const [cardDetailData, setCardDetailData] = useState(null);
    const { subscriptionRenewalDate } = useSelector((state) => state.users?.subscription || {});

    const { postData: userAnalyticsPostData, isLoading: userAnalyticsIsLoading } = useAxios()
    const { postData: monthlyPostData, isLoading: monthlyAnalyticsIsLoading } = useAxios()


    const getUserAnalytics = useCallback(async () => {
        const { success, data, message } = await userAnalyticsPostData(Constants.ApiUrl.company.dashboard.userAnalytics, { from, to });

        if (success) {
            const { topUserAnalytics, caseOverview } = data;
            setUserAnalyticDataPoint(topUserAnalytics);
            setAverageTimeTaken(caseOverview?.averageTimeTaken);
            setTotalNumCases(caseOverview?.totalCases);
        } else {
            toaster({ message, success });
        }
    }, [from, to, userAnalyticsPostData]);

    const getMonthlyAnalytics = useCallback(async () => {
        const { success, data, message } = await monthlyPostData(Constants.ApiUrl.company.dashboard.monthlyAnalytics, {});

        if (success) {
            const { last12MonthData, cardDetail } = data;
            setMonthlyAnalyticDataPoint(last12MonthData);
            setCardDetailData(cardDetail)
        } else {
            toaster({ message, success });
        }
    }, [monthlyPostData]);

    useEffect(() => {
        if (from && to) {
            getUserAnalytics()
        }
    }, [from, to, getUserAnalytics])

    useEffect(() => {
        getMonthlyAnalytics()
    }, [getMonthlyAnalytics])


    const handleFromDateChange = (date) => {
        setFrom(date);
        if (fromDatePickerRef.current) {
            fromDatePickerRef.current.setOpen(false);
        }
    };

    const handelToDateChange = (date) => {
        setTo(date);
        if (toDatePickerRef.current) {
            toDatePickerRef.current.setOpen(false);
        }
    };

    const handleToDateIconClick = () => {
        if (toDatePickerRef.current) {
            toDatePickerRef.current.setOpen(true);
        }
    };

    const handleFromDateIconClick = () => {
        if (fromDatePickerRef.current) {
            fromDatePickerRef.current.setOpen(true);
        }
    };


    return (
        <>
            <div className="graphs-wrap">
                <div className="row-container">
                    <div className="col-md-9 graph-container">
                    
                        <div className="flex m-b-3">
                            <div className="label me-3 f-x-large">Subscription Renewal Date :</div>
                            <div className="label me-3 f-x-large"> {parseAndFormatUtcDate(subscriptionRenewalDate)}</div>
                        </div>
                       
                        
                        <div className="row mb-5 w-100 justify-content-center">
                            <div className="col-md-3">
                                <div className="d-flex align-items-center">
                                    <div className="label me-3">Start Date :</div>
                                    <div className="datepicker-wrap">
                                        <DatePicker
                                            id="start-date-picker"
                                            ref={fromDatePickerRef}
                                            selected={from}
                                            onChange={handleFromDateChange}
                                            maxDate={new Date()}
                                            dateFormat="MM/dd/yyyy"
                                            popperPlacement="bottom"
                                            className="custom-datepicker"
                                        />
                                        <div className="icon-wrapper" onClick={handleFromDateIconClick}>
                                            <FaCalendarAlt className="calendar-icon" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="d-flex align-items-center">
                                    <div className="label me-3">End Date :</div>
                                    <div className="datepicker-wrap">
                                        <DatePicker
                                            id="end-date-picker"
                                            ref={toDatePickerRef}
                                            selected={to}
                                            onChange={handelToDateChange}
                                            maxDate={new Date()}
                                            dateFormat="MM/dd/yyyy"
                                            popperPlacement="bottom"
                                            className="custom-datepicker"
                                        />
                                        <div className="icon-wrapper" onClick={handleToDateIconClick}>
                                            <FaCalendarAlt className="calendar-icon" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="graph-container-one" style={{ paddingTop: '20px', paddingBottom: '20px' }}>

                            {userAnalyticsIsLoading && <XLoader />}

                            {!userAnalyticsIsLoading && <Graph dataPoints={userAnalyticDataPoint} totalNumCases={totalNumCases} graph={true} />}

                        </div>

                    </div>

                    <div className="page-container">
                        <div className="top-content">Average Demand Creation Time:</div>
                        <div className="center-content">{averageTimeTaken} Minutes</div>
                        <div className="bottom-right-text">{totalNumCases} demands</div>
                    </div>
                </div>
            </div>

            <div className="stats-cards">
                <div className="row">
                    <div className="col-md-2">
                        <div className="item">
                            <span className="icon"><i className="fa-solid fa-money-check-dollar"></i></span>
                            <p className="title">Monthly Demand Credits Remaining</p>
                            <span className="count">{cardDetailData?.monthlyRemainingDemand}</span>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="item">
                            <span className="icon"><i className="fa-solid fa-hand-holding-dollar"></i></span>
                            <p className="title">Demand Credits per Month</p>
                            <span className="count">{cardDetailData?.demandsPerMonth}</span>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="item">
                            <span className="icon"><i className="fa-solid fa-chart-line"></i></span>
                            <p className="title">Monthly Ala Carte</p>
                            <span className="count">{cardDetailData?.monthlyAlaCarteDemand}</span>
                        </div>
                    </div> 
                    <div className="col-md-2">
                        <div className="item">
                            <span className="icon"><i className="fa-solid fa-chart-line"></i></span>
                            <p className="title">Total Ala Carte</p>
                            <span className="count">{cardDetailData?.totalAlaCarteDemand}</span>
                        </div>
                    </div>
                    <div className="col-md-2">
                        <div className="item">
                            <span className="icon"><i className="fa-solid fa-hand-holding-dollar"></i></span>
                            <p className="title">Demand Credits Accrued</p>
                            <span className="count">{cardDetailData?.rollOverCredits}</span>
                        </div>
                    </div>

                    <div className="col-md-2">
                        <div className="item">
                            <span className="icon"><i className="fa-solid fa-cube"></i></span>
                            <p className="title">Total User </p>
                            <span className="count">{cardDetailData?.totalUsers}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row row-container mb-4">
                <div className="col-md-12 graph-container">
                    <div className="graph-container-one" style={{ paddingTop: '20px', paddingBottom: '20px' }}>

                        {monthlyAnalyticsIsLoading && <XLoader />}

                        {!monthlyAnalyticsIsLoading && <Graph totalNumCases={totalNumCases} graph={false} MPoints={monthlyAnalyticDataPoint} />}

                    </div>
                </div>
            </div>

        </>
    );
};

export default Dashboard;