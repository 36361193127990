import { Field, Formik, Form } from 'formik'
import * as Yup from 'yup'
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { TextInput } from '../../components';
import { AuthService } from '../../api-services';
import { useEffect, useState } from 'react';
import './style.css'
import LoggedInUserService from '../../services/AuthService'

const ChangePassword = () => {
    // let { id, token } = useParams();
    let [searchParams] = useSearchParams();
    let id = searchParams.get('id');
    let token = searchParams.get('token');
    let navigate = useNavigate();
    const [checkResetToken, setcheckResetToken] = useState(0)
    useEffect(() => {
        const data = LoggedInUserService.GetLoggedInUserData();
        const domainName = data.domainName;
        let params = {
            id,
            token,
            domainName
        }
        console.log(id,token, "id , token");
        setcheckResetToken(0)
        AuthService.checkresetToken(params).then((res) => {
            setcheckResetToken(res.success)
        }).catch(err => {
            setcheckResetToken(err.success)

        })
    }, [id, token])
    const validationSchema = Yup.object().shape({
        password: Yup.string().required('Password is required').min(8, 'Password must be at least 8 characters long'),
        confirmPassword: Yup.string().required('Confirm password is required').oneOf([Yup.ref('password')], 'Passwords must match')
    })
    const onSubmit = (values, setErrors) => {
        const data = LoggedInUserService.GetLoggedInUserData();
        const domain = data.domainName;
        values.user_id = id;
        values.token = token;
        values.domainName = domain;
        AuthService.ResetPassword(values).then((res) => {
            navigate('/account/login')
        }).catch(err => {

        })

    }
    if(checkResetToken===0)
     return null;
    return (
        <>
            {
                checkResetToken ?
                    <div className="content">
                        <div className="top">
                            <div className="text">
                                <h1>Change Password?</h1>
                            </div>
                            <Formik
                                initialValues={{
                                    password: '',
                                    confirmPassword: '',
                                }}
                                enableReinitialize={true}
                                validationSchema={validationSchema}
                                onSubmit={(values, { setErrors }) => {
                                    onSubmit(values, setErrors)
                                }}
                            >
                                {() => (
                                    <Form>
                                        <div className="fields">
                                            <div className="form-group">
                                                <Field name="password" removeMargin={true} shouldDollarRender={true} shouldUserRenderIcon={<> <span style={{
                                                    width: "2.5rem",
                                                    outline: "none",
                                                    height: "48px",
                                                    border: "none",
                                                    position: "absolute"
                                                }} className="input-group-text"><i className="fa-solid fa-key"></i></span></>} className="form-control" type="password" placeholder="Enter Password" component={TextInput} />
                                            </div>
                                            <div className="form-group">
                                                <Field name="confirmPassword" removeMargin={true} shouldDollarRender={true} shouldUserRenderIcon={<> <span style={{
                                                    width: "2.5rem",
                                                    outline: "none",
                                                    height: "48px",
                                                    border: "none",
                                                    position: "absolute"
                                                }} className="input-group-text"><i className="fa-solid fa-key"></i></span></>} className="form-control" type="password" placeholder="Enter Password" component={TextInput} />
                                            </div>
                                        </div>
                                        <button type='submit' className="btn-theme btn-login">Submit</button>
                                    </Form>)}
                            </Formik>
                        </div>
                        <Link className="text-theme" to="/account/login"><i className="fa-solid fa-arrow-left me-2"></i> Back to Login</Link>
                    </div>
                    : 
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 offset-lg-3 col-12">
                                    <div className="success-inner">
                                        <h1>Oops, this link is expired</h1>
                                        <p>This URL is not valid anymore.</p>
                                        <Link className="text-theme" to="/account/login"><i className="fa-solid fa-arrow-left me-2"></i> Back to Login</Link>
                                    </div>
                                </div>
                            </div>
                        </div> 
            }
        </>

    )
}

export default ChangePassword;