import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import useAxios from '../hooks/useAxios';
import { toaster } from '../utils/toast';
import DotLoader from "react-spinners/BeatLoader";
import { TextInput } from '../components';
import LoggedInUserService from '../services/AuthService';
import XButton from '../components/common/XButton';
const companyLogo = require("../assets/images/login-logo.png")


const LoginForm = ({ apiUrl, redirectPath }) => {
    const { isLoading, postData } = useAxios();
    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        email: Yup.string().required('Email is required').email('Please enter a valid email'),
        password: Yup.string().required('Password is required').min(8, 'Password must be at least 8 characters long'),
    });

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            const { success, data, message } = await postData(apiUrl, values);
            if (success) {
                const { token } = data;
                LoggedInUserService.UpdateLoggedInUserSession(token);
                navigate(redirectPath);
            } else {
                toaster({ message, success });
            }
        },
    });

    return (
        <>
            <img src={companyLogo} className="img-fluid" alt="company-logo" style={{ width: '30rem', paddingTop: '6rem', marginBottom: '50px' }} />
            <div className="text">
                <h1>Welcome Back</h1>
                <p>Please enter your details.</p>
            </div>
            <DotLoader
                color={'#00a6ff'}
                loading={isLoading}
                size={30}
                aria-label="Loading Spinner"
                data-testid="loader"
            />
            <form onSubmit={formik.handleSubmit}>
                <div className="fields">
                    <div className="form-group">
                        <TextInput
                            name="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.email && formik.errors.email}
                            removeMargin={true}
                            className="form-control"
                            shouldDollarRender={true}
                            shouldUserRenderIcon={(
                                <span className="input-group-text" style={{
                                    width: "2.5rem",
                                    outline: "none",
                                    height: "48px",
                                    border: "none",
                                    position: "absolute"
                                }} id="basic-addon1">
                                    <i className="fa-solid fa-user"></i>
                                </span>
                            )}
                            placeholder="Enter Email"
                        />
                    </div>
                    <div className="form-group mb-0">
                        <TextInput
                            name="password"
                            type="password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.password && formik.errors.password}
                            removeMargin={true}
                            shouldDollarRender={true}
                            shouldUserRenderIcon={(
                                <span style={{
                                    width: "2.5rem",
                                    outline: "none",
                                    height: "48px",
                                    border: "none",
                                    position: "absolute"
                                }} className="input-group-text">
                                    <i className="fa-solid fa-key"></i>
                                </span>
                            )}
                            className="form-control"
                            placeholder="Enter Password"
                        />
                    </div>
                </div>
                <Link className="frgt" to="/account/forgot-password">Forgot Password?</Link>
                <XButton loading={isLoading} className="btn-theme btn-login" type='submit' buttonText='Login' />
            </form>
        </>
    );
};

export default LoginForm;
