import axios from 'axios';
import AppConfig from '../AppConfig';
import Constants from '../Constants';

class AuthService {
    static Login = (payloadData) => {
        const url = `${AppConfig.ApiBaseUrl}${Constants.ApiUrl.auth.sigin}`;
        return axios.post(url, payloadData)
    }

    static superAdminLogin = (payloadData) => {
        const url = `${AppConfig.ApiBaseUrl}${Constants.ApiUrl.auth.superAdminSignin}`;
        return axios.post(url, payloadData)
    }

    static ForgotPassword = (payloadData) => {
        const url = `${AppConfig.ApiBaseUrl}${Constants.ApiUrl.auth.forgotPassword}`;
        return axios.post(url, payloadData)
    }

    static SendPdfandWordLink = (payloadData, generatePdfUrl, generatedWordLink) => {
        const url = `${AppConfig.ApiBaseUrl}${Constants.ApiUrl.auth.sendPdfandWordLink}`;
        return axios.post(url, payloadData, generatePdfUrl, generatedWordLink)
    }

    static ResetPassword = (payloadData) => {
        const url = `${AppConfig.ApiBaseUrl}${Constants.ApiUrl.auth.resetPassword}`;
        return axios.post(url, payloadData)
    }
    static checkresetToken = (params) => {
        const url = `${AppConfig.ApiBaseUrl}${Constants.ApiUrl.auth.checkresetToken}?user_id=${params?.id}&token=${params?.token}&domainName=${params?.domainName}`;
        return axios.get(url).then(resp => resp.data);
    }
}

export default AuthService;