import { createContext, useContext } from 'react';
import useTemplateForm from './useTemplateForm';

const TemplateFormContext = createContext();

export const TemplateFormProvider = ({ templateType, children }) => {
    const templateForm = useTemplateForm({ templateType });

    return (
        <TemplateFormContext.Provider value={templateForm}>
            {children}
        </TemplateFormContext.Provider>
    );
};

export const useTemplateFormContext = () => useContext(TemplateFormContext);
