export const userLimitReachedHtml = `<div style="display: flex; justify-content: center; align-items: center;">
                  <svg style="width: 100px; height: 100px; color: #18479a;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-.5-13h1v6h-1zm0 8h1v2h-1z"/></svg>
                </div>
                <p>This one is for users: Your firm has used all of the available users per its current plan.  Your administrator can add additional users or upgrade the subscription plan.</p>`

export const caseLimitReachedHtml = `<div style="display: flex; justify-content: center; align-items: center;">
              <svg style="width: 100px; height: 100px; color: #18479a;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-.5-13h1v6h-1zm0 8h1v2h-1z"/></svg>
            </div>
            <p>Your firm users have generated all of the demands available for the month through its current monthly subscription. Don't worry, you can continue to generate demands this month and will be charged at the same rate per demand. The administrator for your firm can also upgrade your subscription plan.</p>`

export const templateEditorDeafultFontFamily = 'monospace'

export const fontFamilyOptions = [
  { key: "times-new-roman", value: "times-new-roman", text: "Times New Roman" },
  { key: "sans-serif", value: "sans-serif", text: "Sans Serif" },
  { key: "serif", value: "serif", text: "Serif" },
  { key: "monospace", value: "monospace", text: "Monospace" },
  { key: "arial", value: "arial", text: "Arial" }
]
