import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { RenderIf } from "../../components";
import XLoader from "../../components/common/XLoader";
import XSelect from "../../components/common/XSelect";
import Constants from "../../Constants";
import '../style.css';
import withTemplateFormContext from "./hoc/withTemplateFormContext";
import TemplateEditableField from './TemplateEditableField';
import { useTemplateFormContext } from "./TemplateFormContext";

const SimplifiedUMPLDTemplate = () => {
    const { templateData, state, onChangeState, sanitizeHtml, getTemplateIsLoading } = useTemplateFormContext()

    return (
        <>
            {getTemplateIsLoading && <XLoader />}

            {!getTemplateIsLoading &&

                <div>
                    <div className="row">
                        <div className="col-md-6 mt-1">

                            <div className="col-md-12">
                                <TemplateEditableField
                                    fieldName="attorneyName"
                                    label="Attorney Name"
                                    placeholder="Enter Attorney Name"
                                />
                            </div>

                            <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="attorneyEmail"
                                    label="Attorney Email"
                                    placeholder="Enter Attorney Email"
                                />
                            </div>

                            <div className="col-md-12 mt-5">
                                <XSelect
                                    emptyOption={false}
                                    label="State"
                                    value={state}
                                    onChange={onChangeState}
                                    options={[{ value: "California", text: "California" }, ...Constants.Dropdowns.States]}
                                />
                            </div>

                            <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="firmAddress"
                                    label="Firm Address"
                                    placeholder="Enter Firm Address"
                                />
                            </div>


                            <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="introductionTitle"
                                    label="Introduction Title"
                                    placeholder="Enter Introduction title"
                                    isReferenceVariableAvailable={true}
                                />


                            </div>

                            <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="introductionDescription"
                                    label="Introduction Description"
                                    placeholder="Enter Introduction Description"
                                    isReferenceVariableAvailable={true}
                                />
                            </div>

                            <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="liabilityTitle"
                                    label="Liability Title"
                                    placeholder="Enter liability title"
                                    isReferenceVariableAvailable={true}
                                />
                            </div>

                            <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="liabilityDescription"
                                    label="Liability Description"
                                    placeholder="Enter liability Description"
                                    isReferenceVariableAvailable={true}
                                />

                            </div>

                            
                            <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="nonEconomicDamageTitle"
                                    label="Non-Economic Damages Title"
                                    placeholder="Enter Non-Economic Damages Title"
                                    isReferenceVariableAvailable={true}
                                />
                            </div>

                            <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="nonEconomicDamageDescription"
                                    label="Non-Economic Damage Description"
                                    placeholder="Enter Non-Economic damages description"
                                    isReferenceVariableAvailable={true}
                                />
                            </div>

                            <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="settlementDemandTitle"
                                    label="Settlement Demand Title"
                                    placeholder="Enter Settlement demand title"
                                    isReferenceVariableAvailable={true}
                                />

                            </div>
                            <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="settlementDemandDescription"
                                    label="Settlement Demand Description"
                                    placeholder="Enter settlement demand description"
                                    isReferenceVariableAvailable={true}
                                />

                            </div>

                            <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="termOfSettlementTitle"
                                    label="Term of Settlement Title"
                                    placeholder="Enter term of settlement title"
                                    isReferenceVariableAvailable={true}
                                />

                            </div>
                            <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="termOfSettlementDescription"
                                    label="Term of Settlement Description"
                                    placeholder="Enter term of settlement description"
                                    isReferenceVariableAvailable={true}
                                />

                            </div>
                            <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="firmName"
                                    label="Firm Name"
                                    placeholder="Enter firm Name"
                                />

                            </div>
                        </div>
                        <div className="col-md-6">
                            <h2>Preview</h2>
                            <div className="editing-Field" >
                                <div className="row">
                                    <div className="col-md-3">
                                        <ReactQuill
                                            value={sanitizeHtml(templateData?.attorneyName)}
                                            modules={{ toolbar: false }}
                                            readOnly={true}
                                            theme="bubble"
                                        />
                                    </div>
                                    <div className="col-md-5">
                                        <RenderIf shouldRender={templateData?.companyLogo}>
                                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                <img
                                                    src={templateData?.companyLogo}
                                                    width="350px"
                                                    height="150px"
                                                    alt="Company Logo"
                                                />
                                            </div>
                                            <ReactQuill
                                                value={sanitizeHtml(templateData?.firmAddress)}
                                                modules={{ toolbar: false }}
                                                readOnly={true}
                                                theme="bubble"
                                            />
                                        </RenderIf>
                                    </div>
                                    <div className="col-md-4">
                                        <ReactQuill
                                            value={sanitizeHtml(templateData?.attorneyEmail)}
                                            modules={{ toolbar: false }}
                                            readOnly={true}
                                            theme="bubble"
                                        />
                                    </div>
                                </div>

                                <ReactQuill
                                    value={sanitizeHtml(templateData?.introductionTitle)}
                                    modules={{ toolbar: false }}
                                    readOnly={true}
                                    theme="bubble"
                                />
                                <ReactQuill
                                    value={sanitizeHtml(templateData?.introductionDescription)}
                                    modules={{ toolbar: false }}
                                    readOnly={true}
                                    theme="bubble"
                                />
                                <ReactQuill
                                    value={sanitizeHtml(templateData?.liabilityTitle)}
                                    modules={{ toolbar: false }}
                                    readOnly={true}
                                    theme="bubble"
                                />
                                <ReactQuill
                                    value={sanitizeHtml(templateData?.liabilityDescription)}
                                    modules={{ toolbar: false }}
                                    readOnly={true}
                                    theme="bubble"
                                />

                                <ReactQuill
                                    value={sanitizeHtml(templateData?.priorMedicalRecordTitle)}
                                    modules={{ toolbar: false }}
                                    readOnly={true}
                                    theme="bubble"
                                />

                                <ReactQuill
                                    value={sanitizeHtml(templateData?.priorMedicalRecordDescription)}
                                    modules={{ toolbar: false }}
                                    readOnly={true}
                                    theme="bubble"
                                />

                                <ReactQuill
                                    value={sanitizeHtml(templateData?.nonEconomicDamageTitle)}
                                    modules={{ toolbar: false }}
                                    readOnly={true}
                                    theme="bubble"
                                />

                                <ReactQuill
                                    value={sanitizeHtml(templateData?.nonEconomicDamageDescription)}
                                    modules={{ toolbar: false }}
                                    readOnly={true}
                                    theme="bubble"
                                />
                                <ReactQuill
                                    value={sanitizeHtml(templateData?.lossOfIncomeTitle)}
                                    modules={{ toolbar: false }}
                                    readOnly={true}
                                    theme="bubble"
                                />
                                <ReactQuill
                                    value={sanitizeHtml(templateData?.lossOfIncomeDescription)}
                                    modules={{ toolbar: false }}
                                    readOnly={true}
                                    theme="bubble"
                                />

                                <ReactQuill
                                    value={sanitizeHtml(templateData?.settlementDemandTitle)}
                                    modules={{ toolbar: false }}
                                    readOnly={true}
                                    theme="bubble"
                                />
                                <ReactQuill
                                    value={sanitizeHtml(templateData?.settlementDemandDescription)}
                                    modules={{ toolbar: false }}
                                    readOnly={true}
                                    theme="bubble"
                                />

                                <ReactQuill
                                    value={sanitizeHtml(templateData?.badFaithExposerTitle)}
                                    modules={{ toolbar: false }}
                                    readOnly={true}
                                    theme="bubble"
                                />

                                <ReactQuill
                                    value={sanitizeHtml(templateData?.badFaithExposerDescription)}
                                    modules={{ toolbar: false }}
                                    readOnly={true}
                                    theme="bubble"
                                />
                                <ReactQuill
                                    value={sanitizeHtml(templateData?.termOfSettlementTitle)}
                                    modules={{ toolbar: false }}
                                    readOnly={true}
                                    theme="bubble"
                                />
                                <ReactQuill
                                    value={sanitizeHtml(templateData?.termOfSettlementDescription)}
                                    modules={{ toolbar: false }}
                                    readOnly={true}
                                    theme="bubble"
                                />
                                <ReactQuill
                                    value={sanitizeHtml(templateData?.firmName)}
                                    modules={{ toolbar: false }}
                                    readOnly={true}
                                    theme="bubble"
                                />
                            </div>
                        </div>
                    </div>
                </div >
            }
        </>
    )
}

export default withTemplateFormContext(SimplifiedUMPLDTemplate, 'SimplifiedUMPLDTemplate')